import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundSolutionApplication from "../../Assets/header background solution application.png";
import Footer from "../../Components/Footer";
import SaShopping1 from "../../Assets/sa shopping 1.png";
import SaShopping1KOR from "../../Assets/sa shopping 1 KOR.png";
import SaShopping2 from "../../Assets/sa shopping 2.png";
import OtherSolutions1 from "../../Assets/App Solution 6.png";
import OtherSolutions2 from "../../Assets/App Solution 7.png";
import OtherSolutions3 from "../../Assets/App Solution 8.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function SaShoppingMalls() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={3} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Solution Applications" : "솔루션 활용분야"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Shopping Malls Security Facilities"
            : "상가/보안시설 방범/치안"
        }`}
        background={BackgroundSolutionApplication}
        colorBorder={"#f48e35"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start w-fit">
        <LeftMenu
          currentChoose={1}
          currentBold={"Shopping Malls Security Facilities"}
        />
        <div className="flex flex-col px-0 sm:px-8">
          <div className="flex flex-col">
            <h2 className="mx-8 font-bold text-3xl lg:text-4xl mb-8">
              {language === "ENG"
                ? "Utilizing Security AI Neural Networks"
                : "보안 인공지능 신경망 활용"}
            </h2>
            <p
              className="mx-8 font-normal text-xl text-justify"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "The face of a person who looks out for sympathy around him track to store information to the seller that you are a person of interest when visiting the store alert to alert you in advance security system. AERY Face TRACKER (Patent pending for personalized face recognition)"
                : "주변에 동정을 살피는 사람의 얼굴 트랙킹 하여 정보 저장 가게 방문 시 요주의 인물임을 판매인에게 Alert을 주어 미리 계하게 하는 시큐리티시스템. AERY Face TRACKER (개인화 안면인식 특허출원 중)."}
            </p>
            <div className="mb-12 w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden sm:overflow-y-hidden">
              <img
                src={language === "ENG" ? SaShopping1 : SaShopping1KOR}
                alt="Sa Shopping 1"
                onContextMenu={(e) => e.preventDefault()}
                className="min-w-[650px] sm:min-w-0 sm:w-full mt-12 px-8"
                data-aos="flip-right"
                data-aos-duration="750"
              />
            </div>
          </div>
          <div className="bg-[#1a1a1a] py-20 w-fit">
            <div className="w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden sm:overflow-y-hidden">
              <img
                src={SaShopping2}
                alt="Sa Shopping 2"
                onContextMenu={(e) => e.preventDefault()}
                className="min-w-[650px] sm:min-w-0 sm:w-full mt-8 px-8 mx-auto"
                data-aos="flip-left"
                data-aos-duration="750"
              />
            </div>
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Solution Area" : "기타 솔루션 영역"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Customized Recommendation Service for the Service Industry"
                : "서비스업 맞춤형 추천 서비스"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Manufacturing Quality Control (QC)"
                : "제조업 품질관리(QC)"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Prevention of Early Detection of Natural Disasters"
                : "자연재해/화재/안전 조기감지 예방"
            }`}
            image1={OtherSolutions1}
            image2={OtherSolutions2}
            image3={OtherSolutions3}
            href1={"/sa-customized"}
            href2={"/sa-manufacturing"}
            href3={"/sa-prevention-disasters"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
