import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundSolutionApplication from "../../Assets/header background solution application.png";
import Footer from "../../Components/Footer";
import SaBank1 from "../../Assets/sa bank 1.png";
import OtherSolutions1 from "../../Assets/App Solution 5.png";
import OtherSolutions2 from "../../Assets/App Solution 6.png";
import OtherSolutions3 from "../../Assets/App Solution 7.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function SaBankSecurity() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={3} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Solution Applications" : "솔루션 활용분야"
        }`}
        subtitle={`${
          language === "ENG" ? "Bank Security" : "은행 방범/보이스피싱/안전"
        }`}
        background={BackgroundSolutionApplication}
        colorBorder={"#f48e35"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start">
        <LeftMenu currentChoose={1} currentBold={"Bank Security"} />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8">
            <h2 className="font-bold text-3xl lg:text-4xl mb-8">
              {language === "ENG"
                ? "Best Security and Fastest Crime Handling"
                : "최고의 보안과 가장 빠른 범죄 처리"}
            </h2>
            <img
              src={SaBank1}
              alt="Sa public 1"
              onContextMenu={(e) => e.preventDefault()}
              className="w-full self-center mb-8"
              data-aos="fade-down"
              data-aos-duration="750"
            />
            <div
              className="border border-[#6e6b6c] rounded-3xl mb-8"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <h3 className="w-full font-bold bg-[#222222] text-white py-2 px-8 text-xl rounded-t-3xl">
                ATM
              </h3>
              <ul className="list-disc p-4 ml-4">
                <li>
                  {language === "ENG"
                    ? "Voice phising delivery book"
                    : "보이스피싱 배달책"}
                </li>
                <li>
                  {language === "ENG"
                    ? "Suspicious customer wearing a hat and mask"
                    : "모자, 마스크를 한 의심 고객"}
                </li>
                <li>
                  {language === "ENG"
                    ? "Keep picking up your phone and going from the ATM machine who finds or sends money"
                    : "계속 휴대폰을 들고 ATM기에서 돈을 찾거나 보내는 사람"}
                </li>
              </ul>
            </div>
            <div
              className="border border-[#6e6b6c] rounded-3xl mb-8"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <h3 className="w-full font-bold bg-[#222222] text-white py-2 px-8 text-xl rounded-t-3xl">
                {language === "ENG" ? "Petition Police" : "청원경찰/지정직원"}
              </h3>
              <ul className="list-disc p-4 ml-4">
                <li>
                  {language === "ENG"
                    ? "When abnormal behavior is detected, a warning is called to the petition and designed staff"
                    : "이상 행동 감지 시 청원경찰 및 지정직원에게 경고 호출"}
                </li>
              </ul>
            </div>
            <div
              className="border border-[#6e6b6c] rounded-3xl mb-8"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <h3 className="w-full font-bold bg-[#222222] text-white py-2 px-8 text-xl rounded-t-3xl">
                {language === "ENG" ? "Cash Trucks" : "현금수송차"}
              </h3>
              <ul className="list-disc p-4 ml-4">
                <li>
                  {language === "ENG"
                    ? "Illegal possession of weapons from the back of a cash truck"
                    : "현금수송차 뒤에서 불법 무기 소지"}
                </li>
                <li>
                  {language === "ENG"
                    ? "When abnormal behavior is detected, ※ Warning alarm to the person transporting cash"
                    : "이상행동 감지 시, ※ 현금수송 하는 사람에게 경고 알람"}
                </li>
              </ul>
            </div>
            <div
              className="border border-[#6e6b6c] rounded-3xl"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <h3 className="w-full font-bold bg-[#222222] text-white py-2 px-8 text-xl rounded-t-3xl">
                {language === "ENG" ? "Thief" : "도둑"}
              </h3>
              <ul className="list-disc p-4 ml-4">
                <li>
                  {language === "ENG"
                    ? "In the event of pickpocketing in front of a bank"
                    : "은행 앞 소매치기 발생 시"}
                </li>
                <li>
                  {language === "ENG"
                    ? "When abnormal behavior is detected in front of the bank"
                    : "은행 앞 이상행동 감지 시"}
                </li>
              </ul>
            </div>
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Solution Area" : "기타 솔루션 영역"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Shopping Malls Security Facilities Crime Prevention"
                : "상가/보안시설 방범/치안"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Customized Recommendation Service for the Service Industry"
                : "서비스업 맞춤형 추천 서비스"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Manufacturing Quality Control (QC)"
                : "제조업 품질관리(QC)"
            }`}
            image1={OtherSolutions1}
            image2={OtherSolutions2}
            image3={OtherSolutions3}
            href1={"/sa-shopping-malls"}
            href2={"/sa-customized"}
            href3={"/sa-manufacturing"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
