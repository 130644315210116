import { Fragment } from "react";
import { Disclosure, Menu, Transition, Popover } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import LogoAery from "../Assets/Logo Aery.png";
import { useLanguage } from "../Context/LanguageContext";

const navigation = [
  {
    name: "Products",
    nameKOR: "제품소개",
    current: false,
    subMenu: [
      {
        name: "AI Vision Features",
        nameKOR: "AI Vision 기능",
        href: "/ai-vision",
      },
      {
        name: "AI BOX System Diagram",
        nameKOR: "AI BOX 시스템 구성도",
        href: "/ai-box",
      },
      {
        name: "AI Face Recognition Solution",
        nameKOR: "AI 안면인식 솔루션",
        href: "/ai-face-recognition",
      },
      {
        name: "AI Fire Detection Solution",
        nameKOR: "AI 화재감지 솔루션",
        href: "/ai-fire-detection",
      },
      {
        name: "AI Abnormal Behavior Detection Solution",
        nameKOR: "AI 이상행동감지 솔루션",
        href: "/ai-abnormal-behavior",
      },
      {
        name: "AI Object Tracking Solution",
        nameKOR: "AI 객체추적 솔루션",
        href: "/ai-object-tracking",
      },
    ],
  },
  {
    name: "Product Application",
    nameKOR: "제품 활용분야",
    current: false,
    subMenu: [
      {
        name: "Apartment Security Management",
        nameKOR: "아파트 보안/화재/안전관리",
        href: "/sa-apartment-security",
      },
      {
        name: "Large Mart Shopping Mall Security",
        nameKOR: "대형마트/쇼핑몰 보안/화재/안전관리",
        href: "/sa-large-mart",
      },
      {
        name: "Public Security Social Safety Net",
        nameKOR: "공공 방범/치안/사회안전망",
        href: "/sa-public-security",
      },
      {
        name: "Bank Security",
        nameKOR: "은행 방범/보이스피싱/안전",
        href: "/sa-bank-security",
      },
      {
        name: "Shopping Malls Security Facilities",
        nameKOR: "상가/보안시설 방범/치안",
        href: "/sa-shopping-malls",
      },
      {
        name: "Customized Recommendation Service",
        nameKOR: "서비스업 맞춤형 추천 서비스",
        href: "/sa-customized",
      },
      {
        name: "Manufacturing Quality Control (QC)",
        nameKOR: "제조업 품질관리(QC)",
        href: "/sa-manufacturing",
      },
      {
        name: "Prevention of Early Detection of Natural Disasters",
        nameKOR: "자연재해/화재/안전 조기감지 예방",
        href: "/sa-prevention-disasters",
      },
    ],
  },
  {
    name: "Business Strategy",
    nameKOR: "사업추진전략",
    current: false,
    subMenu: [
      {
        name: "AI BOX Solution",
        nameKOR: "AI BOX Solution 개발전략",
        href: "/bp-ai-box",
      },
      {
        name: "Franchise Brand Marketing",
        nameKOR: "프랜차이즈 브랜트 마케팅 전략",
        href: "/bp-franchise",
      },
      {
        name: "Public Institution and Local Gevernment Marketing Strategy",
        nameKOR: "공공기관 및 지자체 마케팅 전략",
        href: "/bp-public-institution",
      },
      {
        name: "Solution Advancement Strategy",
        nameKOR: "Solution 고도화 전략",
        href: "/bp-solution-advance",
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbars({ textblack, currentBold }) {
  const { language, handleLanguage } = useLanguage();

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-24 items-center justify-between">
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/" className="hover:opacity-75">
                    <img
                      className="h-16 w-auto"
                      src={LogoAery}
                      alt="AERY Company"
                    />
                  </a>
                </div>
              </div>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button className="flex flex-row justify-center items-center lg:hidden outline-none">
                      <GlobeAltIcon
                        className={`block h-8 w-8 ${
                          textblack ? "text-black" : "text-white"
                        }`}
                      />
                      <ChevronDownIcon
                        className={
                          open
                            ? `block h-4 w-4 self-center text-center transition-transform rotate-180 transform ${
                                textblack ? "text-black" : "text-white"
                              }`
                            : `block h-4 w-4 self-center text-center transition-transform -rotate-0 transform ${
                                textblack ? "text-black" : "text-white"
                              }`
                        }
                      />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-0"
                      enterTo="opacity-100 translate-y-1"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-1"
                      leaveTo="opacity-0 translate-y-0"
                    >
                      <Popover.Panel className="bg-white top-8 right-2 absolute z-10 flex flex-col">
                        {({ close }) => (
                          <>
                            <button
                              className="hover:underline hover:bg-gray-300 px-3 py-1 border-b border-black"
                              onClick={() => {
                                language === "ENG" && handleLanguage("KOR");
                                close();
                              }}
                            >
                              <span
                                className={`${
                                  language === "KOR" && "font-bold"
                                }`}
                              >
                                KOR
                              </span>
                            </button>
                            <button
                              className="hover:underline hover:bg-gray-300 px-3 py-1"
                              onClick={() => {
                                language === "KOR" && handleLanguage("ENG");
                                close();
                              }}
                            >
                              <span
                                className={`${
                                  language === "ENG" && "font-bold"
                                }`}
                              >
                                ENG
                              </span>
                            </button>
                          </>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden sm:ml-6 lg:block">
                  <div className="flex space-x-4">
                    <a
                      href={"/about-us"}
                      className={classNames(
                        "hover:bg-gray-700 rounded-md px-3 py-2 text-sm items-center",
                        textblack
                          ? "text-[#1a1a1a]  hover:text-white"
                          : "text-white",
                        currentBold === 1 ? "font-bold" : "font-normal"
                      )}
                    >
                      {language === "ENG" ? "About Us" : "회사소개"}
                    </a>
                    {navigation.map((item) => (
                      <Menu
                        as="div"
                        key={item.name}
                        className="group relative items-center"
                      >
                        <Menu.Button
                          className={classNames(
                            "relative flex hover:bg-gray-700 rounded-md px-3 py-2 text-sm items-center",
                            textblack
                              ? "text-[#1a1a1a] hover:text-white"
                              : "text-white",
                            (currentBold === 2 && item.name === "Products") ||
                              (currentBold === 3 &&
                                item.name === "Product Application") ||
                              (currentBold === 4 &&
                                item.name === "Business Strategy")
                              ? "font-bold"
                              : "font-normal"
                          )}
                        >
                          {language === "ENG" ? item.name : item.nameKOR}
                          <ChevronDownIcon
                            className={classNames(
                              textblack
                                ? "h-4 w-4 text-[#1a1a1a] ml-2 hover:text-white"
                                : "h-4 w-4 text-white ml-2 hover:text-[#1a1a1a]"
                            )}
                          />
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {item.subMenu &&
                              item.subMenu.map((subItem) => (
                                <Menu.Item key={subItem.name}>
                                  {({ active }) => (
                                    <a
                                      href={subItem.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-[#1a1a1a]"
                                      )}
                                    >
                                      {language === "ENG"
                                        ? subItem.name
                                        : subItem.nameKOR}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ))}
                    <a
                      href={"#contactus"}
                      className={classNames(
                        "hover:bg-gray-700 hover:text-white rounded-sm px-3 py-2 text-sm font-normal items-center border",
                        textblack
                          ? "text-[#1a1a1a] border-black"
                          : "text-white border-white"
                      )}
                    >
                      {language === "ENG" ? "Contact Us" : "연락처"}
                    </a>
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button className="lg:flex lg:flex-row justify-center items-center hidden outline-none ml-8">
                            <GlobeAltIcon
                              className={`block h-8 w-8 self-center text-center ${
                                textblack ? "text-black" : "text-white"
                              }`}
                            />
                            <ChevronDownIcon
                              className={
                                open
                                  ? `block h-4 w-4 self-center text-center transition-transform rotate-180 transform ${
                                      textblack ? "text-black" : "text-white"
                                    }`
                                  : `block h-4 w-4 self-center text-center transition-transform -rotate-0 transform ${
                                      textblack ? "text-black" : "text-white"
                                    }`
                              }
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-0"
                            enterTo="opacity-100 translate-y-1"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-1"
                            leaveTo="opacity-0 translate-y-0"
                          >
                            <Popover.Panel className="bg-white top-8 right-2 absolute z-10 flex flex-col">
                              {({ close }) => (
                                <>
                                  <button
                                    className="hover:underline hover:bg-gray-300 px-3 py-1 border-b border-black"
                                    onClick={() => {
                                      language === "ENG" &&
                                        handleLanguage("KOR");
                                      close();
                                    }}
                                  >
                                    <span
                                      className={`${
                                        language === "KOR" && "font-bold"
                                      }`}
                                    >
                                      KOR
                                    </span>
                                  </button>
                                  <button
                                    className="hover:underline hover:bg-gray-300 px-3 py-1"
                                    onClick={() => {
                                      language === "KOR" &&
                                        handleLanguage("ENG");
                                      close();
                                    }}
                                  >
                                    <span
                                      className={`${
                                        language === "ENG" && "font-bold"
                                      }`}
                                    >
                                      ENG
                                    </span>
                                  </button>
                                </>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div
              className={classNames(
                "absolute z-50 flex flex-col w-full space-y-1 px-2 pb-3 pt-2",
                textblack ? "bg-gray-300" : "bg-gray-500"
              )}
            >
              <a
                href={"/about-us"}
                className={classNames(
                  "text-center hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm items-center",
                  textblack ? "text-[#1a1a1a]" : "text-white",
                  currentBold === 1 ? "font-bold" : "font-normal"
                )}
              >
                {language === "ENG" ? "About Us" : "회사소개"}
              </a>
              {navigation.map((item) => (
                <Menu as="div" key={item.name} className="group relative">
                  <Menu.Button
                    className={classNames(
                      "relative w-full justify-between hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm items-center",
                      textblack ? "text-[#1a1a1a]" : "text-white",
                      (currentBold === 2 && item.name === "Products") ||
                        (currentBold === 3 &&
                          item.name === "Product Application") ||
                        (currentBold === 4 && item.name === "Business Strategy")
                        ? "font-bold"
                        : "font-normal"
                    )}
                  >
                    <span>{language === "ENG" ? item.name : item.nameKOR}</span>
                    <ChevronDownIcon
                      className={classNames(
                        textblack
                          ? "h-4 w-full text-[#1a1a1a] hover:text-white"
                          : "h-4 w-full text-white hover:text-[#1a1a1a]"
                      )}
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="w-full absolute z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {item.subMenu &&
                        item.subMenu.map((subItem) => (
                          <Menu.Item key={subItem.name}>
                            {({ active }) => (
                              <a
                                href={subItem.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-[#1a1a1a]"
                                )}
                              >
                                {language === "ENG"
                                  ? subItem.name
                                  : subItem.nameKOR}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              ))}
              <a
                href={"#contactus"}
                className={classNames(
                  "text-center rounded-sm hover:bg-gray-700 px-3 py-2 text-sm font-normal items-center",
                  textblack ? "text-[#1a1a1a] hover:text-white" : "text-white"
                )}
              >
                {language === "ENG" ? "Contact Us" : "연락처"}
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
