import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundBusinessStrategy from "../../Assets/header background business strategy.png";
import Footer from "../../Components/Footer";
import RightArrow from "../../Assets/right-arrow 1.svg";
import BPAiBoxImg from "../../Assets/BP AI BOX.png";
import OtherBusiness1 from "../../Assets/Other Business Strategy 2.png";
import OtherBusiness2 from "../../Assets/Other Business Strategy 3.png";
import OtherBusiness3 from "../../Assets/Other Business Strategy 4.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function BPAiBox() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={4} />
      <HeaderContent
        title={`${
          language === "ENG" ? "Business Promotion Strategy" : "사업추진전략"
        }`}
        subtitle={`${
          language === "ENG"
            ? "AI BOX Solution Development Strategy"
            : "AI BOX Solution 개발전략"
        }`}
        background={BackgroundBusinessStrategy}
        colorBorder={"#f54545"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start w-fit">
        <LeftMenu currentChoose={2} currentBold={"AI BOX Solution"} />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8">
            <h2 className="font-bold text-3xl mb-8">
              {language === "ENG"
                ? "Introduction AERY AI BOX System"
                : "AI BOX Solution 개발전략"}
            </h2>
            <div
              className="flex flex-row items-start sm:items-center mt-4 space-x-2"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <img
                src={RightArrow}
                className="w-8 sm:w-12"
                alt="Right Arrow"
                onContextMenu={(e) => e.preventDefault()}
              />
              <span className="text-lg sm:text-xl">
                {language === "ENG"
                  ? "Developed by AI BOX method to maximize the utilization of the solution → Reuse of existing CCTV systems"
                  : "Solution의 활용성 극대화를 위해 AI BOX 방식으로 개발 → 기존 CCTV 시스템 재사용"}
              </span>
            </div>
            <div
              className="flex flex-row items-start sm:items-center mt-4 space-x-2"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <img
                src={RightArrow}
                className="w-8 sm:w-12"
                alt="Right Arrow"
                onContextMenu={(e) => e.preventDefault()}
              />
              <span className="text-lg sm:text-xl">
                {language === "ENG"
                  ? "Developing a fast and accurate AI BOX Solution system using AI development experts"
                  : "AI개발 전문연구인력 이용하여 빠르고 정확한 AI BOX Solution 시스템 개발"}
              </span>
            </div>
            <img
              src={BPAiBoxImg}
              alt="AI BOX Solution Development"
              onContextMenu={(e) => e.preventDefault()}
              className="w-full mt-12"
              data-aos="flip-down"
              data-aos-duration="750"
            />
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Business Strategy" : "기타 사업 전략"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Franchise Brand Marketing Strategy"
                : "프랜차이즈 브랜트 마케팅 전략"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Public Institution and Local Government Marketing Strategy"
                : "공공기관 및 지자체 마케팅 전략"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Solution Advancement Strategy"
                : "Solution 고도화 전략"
            }`}
            image1={OtherBusiness1}
            image2={OtherBusiness2}
            image3={OtherBusiness3}
            href1={"/bp-franchise"}
            href2={"/bp-public-institution"}
            href3={"/bp-solution-advance"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
