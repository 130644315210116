import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundBusinessStrategy from "../../Assets/header background business strategy.png";
import Footer from "../../Components/Footer";
import RightArrow from "../../Assets/right-arrow 1.svg";
import BPFranchiseImg from "../../Assets/BP Franchise.png";
import BPFranchiseKORImg from "../../Assets/BP Franchise KOR.png";
import OtherBusiness1 from "../../Assets/Other Business Strategy 3.png";
import OtherBusiness2 from "../../Assets/Other Business Strategy 4.png";
import OtherBusiness3 from "../../Assets/Other Business Strategy 1.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function BPFranchiseBrand() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={4} />
      <HeaderContent
        title={`${
          language === "ENG" ? "Business Promotion Strategy" : "사업추진전략"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Franchise Brand Marketing"
            : "프랜차이즈 브랜트 마케팅 전략"
        }`}
        background={BackgroundBusinessStrategy}
        colorBorder={"#f54545"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start w-fit">
        <LeftMenu currentChoose={2} currentBold={"Franchise Brand Marketing"} />
        <div className="flex flex-col px-0 sm:px-8">
          <div className="flex flex-col">
            <h2 className="mx-8 font-bold text-3xl mb-8">
              {language === "ENG"
                ? "Great benefits lead to great strategy"
                : "프렌차이즈 브랜드 마케팅 전략"}
            </h2>
            <div
              className="mx-8 flex flex-row items-start sm:items-center space-x-2"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <img
                src={RightArrow}
                className="w-8 sm:w-12"
                alt="Right Arrow"
                onContextMenu={(e) => e.preventDefault()}
              />
              <span className="text-lg sm:text-xl">
                {language === "ENG"
                  ? "Establishing a relationship network with franchise businesses, PG Company, and Van operators and forming strategic partnerships."
                  : "Franchise 기업과의 관계망이 구축 되어 있는 PG社 및 Van 사업자와 전략적 제휴"}
              </span>
            </div>
            <div
              className="mx-8 flex flex-row items-start sm:items-center mt-4 space-x-2"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <img
                src={RightArrow}
                className="w-8 sm:w-12"
                alt="Right Arrow"
                onContextMenu={(e) => e.preventDefault()}
              />
              <span className="text-lg sm:text-xl">
                {language === "ENG"
                  ? "Building an integrated AERY Solution within the franchise for market dominance (currently in contract negotiations with PG Company)."
                  : "가맹 본부에 AERY 통합 Solution 구축하여 시장선점 (현재 PG社와 계약 진행중)"}
              </span>
            </div>
            <div className="w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden">
              <img
                src={language === "ENG" ? BPFranchiseImg : BPFranchiseKORImg}
                alt="Franchise Branding"
                onContextMenu={(e) => e.preventDefault()}
                className="min-w-[700px] sm:min-w-0 sm:w-full mt-12 px-8"
                data-aos="flip-down"
                data-aos-duration="750"
              />
            </div>
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Business Strategy" : "기타 사업 전략"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Public Institution and Local Government Marketing Strategy"
                : "공공기관 및 지자체 마케팅 전략"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Solution Advancement Strategy"
                : "Solution 고도화 전략"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "AI BOX Solution Development Strategy"
                : "AI BOX Solution 개발전략"
            }`}
            image1={OtherBusiness1}
            image2={OtherBusiness2}
            image3={OtherBusiness3}
            href1={"/bp-public-institution"}
            href2={"/bp-solution-advance"}
            href3={"/bp-ai-box"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
