import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundSolutionApplication from "../../Assets/header background solution application.png";
import Footer from "../../Components/Footer";
import SaCustomized3 from "../../Assets/sa customized 3.png";
import SaCustomized1KOR from "../../Assets/sa customized 1 KOR.png";
import OtherSolutions1 from "../../Assets/App Solution 7.png";
import OtherSolutions2 from "../../Assets/App Solution 8.png";
import OtherSolutions3 from "../../Assets/App Solution 1.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function SaCustomizedRecommend() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={3} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Solution Applications" : "솔루션 활용분야"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Customized Recommendation Service"
            : "서비스업 맞춤형 추천 서비스"
        }`}
        background={BackgroundSolutionApplication}
        colorBorder={"#f48e35"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start">
        <LeftMenu
          currentChoose={1}
          currentBold={"Customized Recommendation Service"}
        />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8">
            <h2 className="font-bold text-3xl lg:text-4xl mb-8">
              {language === "ENG"
                ? "Utilization of AI Neural Networks in the Service Industry"
                : "서비스업 AI 신경망 활용"}
            </h2>
            <img
              src={language === "ENG" ? SaCustomized3 : SaCustomized1KOR}
              alt="Sa customized 2"
              onContextMenu={(e) => e.preventDefault()}
              className="w-full self-center mb-4 p-4 border border-black rounded-xl shadow-lg"
              data-aos="flip-right"
              data-aos-duration="750"
            />
            <div
              className="bg-[#1a1a1a] px-9 py-8 md:py-12 md:px-12 text-white rounded-2xl"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <h2 className="text-xl md:text-2xl font-bold underline text-center mb-4">
                {language === "ENG"
                  ? "Count Monitor Screen"
                  : "카운트 모니터 화면"}
              </h2>
              <ul className="list-disc">
                <li>
                  {language === "ENG"
                    ? "Notification of returning customers (Responding to customers by identifying the food they ate during their last visit)"
                    : "재방문한 고객 알림 (지난 방문 드신 음식 파악으로 고객응대)"}
                </li>
                <li>
                  {language === "ENG"
                    ? "Black consumer correspondence"
                    : "Black Consumer 대응"}
                </li>
                <li>
                  {language === "ENG"
                    ? "Complain correspondence"
                    : "Complain 대응"}
                </li>
              </ul>
            </div>
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Solution Area" : "기타 솔루션 영역"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Manufacturing Quality Control (QC)"
                : "제조업 품질관리(QC)"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Prevention of Early Detection of Natural Disasters"
                : "자연재해/화재/안전 조기감지 예방"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Apartment Security Management"
                : "아파트 보안/화재/안전관리"
            }`}
            image1={OtherSolutions1}
            image2={OtherSolutions2}
            image3={OtherSolutions3}
            href1={"/sa-manufacturing"}
            href2={"/sa-prevention-disasters"}
            href3={"/sa-apartment-security"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
