import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { Transition } from "@headlessui/react";
import { useLanguage } from "../Context/LanguageContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LeftMenu({ className, currentBold, currentChoose }) {
  const [productIntroduction, setProductIntroduction] = useState(false);
  const [solutionApplication, setSolutionApplication] = useState(false);
  const [businessPromotion, setBusinessPromotion] = useState(false);

  const { language } = useLanguage();

  const navigation = [
    {
      name: "AERY Product Introduction",
      nameKOR: "(주)에리 제품소개",
      current: false,
      subMenu: [
        {
          name: "AI Vision Features",
          nameKOR: "AI Vision 기능",
          href: "/ai-vision",
        },
        {
          name: "AI BOX System Diagram",
          nameKOR: "AI BOX 시스템 구성도",
          href: "/ai-box",
        },
        {
          name: "AI Face Recognition Solution",
          nameKOR: "AI 안면인식 솔루션",
          href: "/ai-face-recognition",
        },
        {
          name: "AI Fire Detection Solution",
          nameKOR: "AI 화재감지 솔루션",
          href: "/ai-fire-detection",
        },
        {
          name: "AI Abnormal Behavior Detection Solution",
          nameKOR: "AI 이상행동감지 솔루션",
          href: "/ai-abnormal-behavior",
        },
        {
          name: "AI Object Tracking Solution",
          nameKOR: "AI 객체추적 솔루션",
          href: "/ai-object-tracking",
        },
      ],
    },
    {
      name: "AERY Solution Application",
      nameKOR: "제품 활용분야",
      current: false,
      subMenu: [
        {
          name: "Apartment Security Management",
          nameKOR: "아파트 보안/화재/안전관리",
          href: "/sa-apartment-security",
        },
        {
          name: "Large Mart Shopping Mall Security",
          nameKOR: "대형마트/쇼핑몰 보안/화재/안전관리",
          href: "/sa-large-mart",
        },
        {
          name: "Public Security Social Safety Net",
          nameKOR: "공공 방범/치안/사회안전망",
          href: "/sa-public-security",
        },
        {
          name: "Bank Security",
          nameKOR: "은행 방범/보이스피싱/안전",
          href: "/sa-bank-security",
        },
        {
          name: "Shopping Malls Security Facilities",
          nameKOR: "상가/보안시설 방범/치안",
          href: "/sa-shopping-malls",
        },
        {
          name: "Customized Recommendation Service",
          nameKOR: "서비스업 맞춤형 추천 서비스",
          href: "/sa-customized",
        },
        {
          name: "Manufacturing Quality Control (QC)",
          nameKOR: "제조업 품질관리(QC)",
          href: "/sa-manufacturing",
        },
        {
          name: "Prevention of Early Detection of Natural Disasters",
          nameKOR: "자연재해/화재/안전 조기감지 예방",
          href: "/sa-prevention-disasters",
        },
      ],
    },
    {
      name: "Business Promotion Strategy",
      nameKOR: "사업추진전략",
      current: false,
      subMenu: [
        {
          name: "AI BOX Solution",
          nameKOR: "AI BOX Solution 개발전략",
          href: "/bp-ai-box",
        },
        {
          name: "Franchise Brand Marketing",
          nameKOR: "프랜차이즈 브랜트 마케팅 전략",
          href: "/bp-franchise",
        },
        {
          name: "Public Institution and Local Government Marketing Strategy",
          nameKOR: "공공기관 및 지자체 마케팅 전략",
          href: "/bp-public-institution",
        },
        {
          name: "Solution Advancement Strategy",
          nameKOR: "Solution 고도화 전략",
          href: "/bp-solution-advance",
        },
      ],
    },
  ];

  const clickMenu = (submenu) => {
    if (submenu === "AERY Product Introduction") {
      setProductIntroduction(!productIntroduction);
    } else if (submenu === "AERY Solution Application") {
      setSolutionApplication(!solutionApplication);
    } else if (submenu === "Business Promotion Strategy") {
      setBusinessPromotion(!businessPromotion);
    }
  };

  return (
    <div
      className={classNames(
        "hidden sticky md:top-8 2xl:top-28 mx-8 md:flex flex-col w-3/4 2xl:w-1/2",
        className
      )}
    >
      {navigation &&
        navigation.map((item, index) => (
          <div key={index} className="w-full">
            <button
              className={classNames(
                "text-left flex flex-row items-center justify-between py-2 px-2 w-full hover:underline border-l border-gray-600 text-black",
                currentChoose === index ? "font-bold border-l-2" : "font-normal"
              )}
              onClick={() => clickMenu(item.name)}
            >
              <span className="w-full">
                {language === "ENG" ? item.name : item.nameKOR}
              </span>
              <ChevronRightIcon
                className={classNames(
                  "w-4 text-black",
                  (item.name === "AERY Product Introduction" &&
                  productIntroduction
                    ? "transition-transform transform ease-in rotate-90"
                    : "") ||
                    (item.name === "AERY Solution Application" &&
                    solutionApplication
                      ? "transition-transform transform ease-in rotate-90"
                      : "") ||
                    (item.name === "Business Promotion Strategy" &&
                    businessPromotion
                      ? "transition-transform transform ease-in rotate-90"
                      : "")
                )}
              />
            </button>
            <Transition
              show={
                (item.name === "AERY Product Introduction" &&
                  productIntroduction) ||
                (item.name === "AERY Solution Application" &&
                  solutionApplication) ||
                (item.name === "Business Promotion Strategy" &&
                  businessPromotion)
              }
              enter="transition ease duration-400 transform"
              enterFrom="opacity-0 -translate-y-8"
              enterTo="opacity-100"
              leave="transition ease duration-400 transform"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 -translate-y-8"
              className={"w-full"}
            >
              <ul className="w-full border-l border-gray-600">
                {item.subMenu &&
                  item.subMenu.map((list, index) => (
                    <li
                      key={index}
                      className={classNames(
                        "transition-transform transform hover:translate-x-2 text-xs py-1 px-2 w-full text-black",
                        currentBold === list.name ? "font-bold" : "font-normal"
                      )}
                    >
                      <a href={list.href}>
                        {language === "ENG" ? list.name : list.nameKOR}
                      </a>
                    </li>
                  ))}
              </ul>
            </Transition>
          </div>
        ))}
    </div>
  );
}
