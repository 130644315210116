import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundProductIntroduction from "../../Assets/header background product introduction.png";
import Footer from "../../Components/Footer";
import AiAbnormalImg from "../../Assets/ai abnormal.png";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import LogoAeryWhite from "../../Assets/Logo Aery white.png";
import OtherProduct1 from "../../Assets/AI Object Tracking Other.png";
import OtherProduct2 from "../../Assets/AI Vision Other.png";
import OtherProduct3 from "../../Assets/AI Box Other.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function AiAbnormalBehav() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={2} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Product Introduction" : "제품 소개"
        }`}
        subtitle={`${
          language === "ENG"
            ? "AI Abnormal Behavior Detection Solution"
            : "AI 이상행동감지 솔루션"
        }`}
        background={BackgroundProductIntroduction}
        colorBorder={"#32a7df"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start">
        <LeftMenu
          currentChoose={0}
          currentBold={"AI Abnormal Behavior Detection Solution"}
        />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8">
            <h2 className="font-bold text-3xl mb-8">
              {language === "ENG"
                ? "Prevention of Security Breaches and Threats"
                : "보안 침해 및 위협 방지"}
            </h2>
            <p
              className="font-normal text-xl"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "Illegal possession of weapons or violent situations are determined by AERY (0.3 seconds) and a warning notification is displayed to the control center."
                : "불법무기를 소지하거나 폭력적인 상황을 AERY(0.3초)판단 하여 관제 센터에 경고 알림 표시."}
            </p>
            <img
              src={AiAbnormalImg}
              alt="AI Abnormal Behavior"
              onContextMenu={(e) => e.preventDefault()}
              className="w-full mt-12"
              data-aos="flip-up"
              data-aos-duration="750"
            />
            <div
              className="w-full mt-12 mb-4 border border-black rounded-3xl shadow-md"
              data-aos="flip-down"
              data-aos-duration="750"
            >
              <p className=" bg-[#1a1a1a] rounded-t-3xl text-center text-[#fefefe] p-4 font-bold text-2xl md:text-3xl">
                {language === "ENG" ? "STANDARD METHOD" : "기존 방법"}
              </p>
              <p className="p-8 font-medium text-xl sm:text-2xl text-justify">
                {language === "ENG"
                  ? "Response based on reports from people in the vicinity often results in significant casualties before taking action."
                  : "기존 주위사람 신고에 의한 현장 출동은 많은 인명 피해 후 조치 방법"}
              </p>
            </div>
            <ArrowDownIcon
              className="w-20 self-center"
              data-aos="zoom-out-down"
              data-aos-duration="750"
            />
            <div
              className="w-full mt-4 mb-12 border border-black rounded-3xl shadow-md"
              data-aos="flip-down"
              data-aos-duration="750"
            >
              <div className="bg-[#ffc700] border-b border-black rounded-t-3xl text-center text-[#fefefe] p-4 font-bold text-4xl">
                <img
                  src={LogoAeryWhite}
                  alt="Logo Aery White"
                  onContextMenu={(e) => e.preventDefault()}
                  className="max-w-[60%] sm:w-1/4 md:w-1/3 lg:w-1/4 mx-auto"
                />
              </div>
              <p className="p-8 font-medium text-xl sm:text-2xl text-justify">
                {language === "ENG"
                  ? 'Real-time surveillance without missing a 0.1 second Alert notification when abnormal behavior is detected After the control personnel check the on-site CCTV, they request on-site support. "Minimizing Casualties"'
                  : "0.1초도 놓치지 않고 실시간 감시 이상행동 감지 시 경고 알림 관제요원이 현장 CCTV 확인 후 현장 지원 요청 “인명피해 최소화”"}
              </p>
            </div>
          </div>
          <OtherContent
            title={`${language === "ENG" ? "Other Products" : "다른 제품들"}`}
            titlecard1={`${
              language === "ENG"
                ? "AI Object Tracking Solution"
                : "AI 객체추적 솔루션"
            }`}
            titlecard2={`${
              language === "ENG" ? "AI Vision Features" : "AI Vision 기능"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "AI BOX System Diagram"
                : "AI BOX 시스템 구성도"
            }`}
            image1={OtherProduct1}
            image2={OtherProduct2}
            image3={OtherProduct3}
            href1={"/ai-object-tracking"}
            href2={"/ai-vision"}
            href3={"/ai-box"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
