import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundProductIntroduction from "../../Assets/header background product introduction.png";
import Footer from "../../Components/Footer";
import AiObject1 from "../../Assets/ai object tracking 1.png";
import AiObject2 from "../../Assets/ai object tracking 2.svg";
import AiObject3 from "../../Assets/ai object tracking 3.svg";
import AiObject4 from "../../Assets/ai object tracking 4.png";
import AiObject4KOR from "../../Assets/ai object tracking 4 KOR.png";
import { ArrowDownIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import LogoAeryWhite from "../../Assets/Logo Aery white.png";
import OtherProduct1 from "../../Assets/AI Vision Other.png";
import OtherProduct2 from "../../Assets/AI Box Other.png";
import OtherProduct3 from "../../Assets/AI Face Other.png";
import LeftMenu from "../../Components/LeftMenu";
import OtherContent from "../../Components/OtherContent";
import HeaderContent from "../../Components/HeaderContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function AiObjectTracking() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={2} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Product Introduction" : "제품 소개"
        }`}
        subtitle={`${
          language === "ENG"
            ? "AI Object Tracking Solution"
            : "AI 객체추적 솔루션"
        }`}
        background={BackgroundProductIntroduction}
        colorBorder={"#32a7df"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start w-fit">
        <LeftMenu
          currentChoose={0}
          currentBold={"AI Object Tracking Solution"}
        />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8">
            <h2 className="font-bold text-3xl mb-4">
              {language === "ENG"
                ? "Efficient Tracking of Multiple Objects"
                : "과거 및 실시간 동선 추적"}
            </h2>
            <p
              className="font-normal mb-8 text-xl"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "Everyone who enters the AERY ZONE AREA is given a unique and random code. If each CCTV is connected to the AERY AI BOX, it is possible to track the movement with unique code."
                : "AERY ZONE 영역에 들어 오는 모든 사람들은 개인별 랜덤 고유 코드가 부여 된다. 각각의 CCTV가 AERY AI BOX 연결이 되어 있으면 고유 코드로 동선 추적이 가능 하다."}
            </p>
            <img
              src={AiObject1}
              alt="AI Object Tracking 1"
              onContextMenu={(e) => e.preventDefault()}
              className="max-w-full lg:max-w-[70%] mx-auto"
              data-aos="flip-up"
              data-aos-duration="750"
            />
            <h2
              className="text-center text-lg sm:text-2xl mt-4"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "Track historical and real-time movements tracking"
                : "과거 및 실시간 동선 추적"}
            </h2>

            <div
              className="flex flex-col sm:flex-row sm:space-x-2 mt-12 mb-20 justify-center"
              data-aos="flip-up"
              data-aos-duration="750"
            >
              <div className="w-full border border-black rounded-3xl shadow-md">
                <p className=" bg-[#1a1a1a] rounded-t-3xl text-center text-[#fefefe] p-4 font-bold text-xl md:text-2xl">
                  {language === "ENG" ? "STANDARD METHOD" : "기존 방법"}
                </p>
                <p className="p-4 font-medium text-lg lg:text-xl text-justify">
                  {language === "ENG"
                    ? "CCTV Individual Cameras Searching for recorded videos huge time consumption"
                    : "CCTV 개별 카메라 녹화영상 검색 엄청난 시간 소비"}
                </p>
              </div>
              <ArrowRightIcon className="hidden sm:block w-16 md:w-28 self-center" />
              <ArrowDownIcon className="block sm:hidden w-16 md:w-28 self-center" />
              <div className="w-full border border-black rounded-3xl shadow-md">
                <div className="bg-[#ffc700] border-b border-black rounded-t-3xl text-center text-[#fefefe] p-2 font-bold text-4xl">
                  <img
                    src={LogoAeryWhite}
                    alt="Logo Aery White"
                    onContextMenu={(e) => e.preventDefault()}
                    className="max-w-[40%] sm:w-1/3 md:w-3/4 lg:w-1/3 md:max-w-full mx-auto"
                  />
                </div>
                <p className="p-4 font-medium text-lg lg:text-xl text-justify">
                  {language === "ENG"
                    ? "CCTV Full Code Value In seconds with input Movement can be traced"
                    : "CCTV 전체 코드값 입력으로 몇 초 안에 동선 추적 가능"}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#1a1a1a] py-20">
            <div className="flex flex-col space-y-8 mx-8">
              <div
                className="flex flex-col sm:flex-row sm:space-x-4 justify-center items-center text-[#fefefe] font-medium text-lg sm:text-2xl space-y-4"
                data-aos="fade-down"
                data-aos-duration="750"
              >
                <img
                  src={AiObject2}
                  alt="AI Object Tracking 2"
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-full sm:w-1/2"
                />
                <span className="border-white border-2 p-8 rounded-3xl font-regular text-justify">
                  {language === "ENG"
                    ? "With individual unique numbers assigned within the AERY ZONE, it is possible to track past and real-time routes."
                    : "AERY ZONE 내 개인별 고유 넘버 부여로 과거 및 실시간 동선 추적이 가능하다."}
                </span>
              </div>
              <div
                className="flex flex-col-reverse sm:flex-row sm:space-x-4 justify-center items-center text-[#fefefe] space-y-4"
                data-aos="fade-up"
                data-aos-duration="750"
              >
                <div className="border-white border-2 p-8 rounded-3xl text-lg sm:text-2xl">
                  <span className="text-2xl font-bold">
                    {language === "ENG"
                      ? "With just one input of a code:"
                      : "코드값 입력 하나로 :"}
                  </span>
                  <ul className="list-disc ml-8 mt-2 text-base sm:text-xl">
                    <li className="font-regular">
                      {language === "ENG"
                        ? "Tracking of past suspect routes"
                        : "과거 용의자 동선 추적"}
                    </li>
                    <li className="font-regular">
                      {language === "ENG"
                        ? "Real-time suspect route tracking"
                        : "실시간 용의자 동선 추적"}
                    </li>
                    <li className="font-regular">
                      {language === "ENG"
                        ? "Tracking of wanted criminals, dementia patients, and missing individual’s routes"
                        : "지명수배범, 치매노인, 미아 동선 추적"}
                    </li>
                  </ul>
                </div>
                <img
                  src={AiObject3}
                  alt="AI Object Tracking 3"
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-full sm:w-1/2"
                />
              </div>
            </div>
          </div>
          <div className="w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden">
            <img
              src={language === "ENG" ? AiObject4 : AiObject4KOR}
              alt="AI Object Tracking 4"
              onContextMenu={(e) => e.preventDefault()}
              className="min-w-[600px] sm:min-w-0 sm:w-full lg:w-[70%] mx-auto mt-12 px-8"
            />
          </div>
          <OtherContent
            title={`${language === "ENG" ? "Other Products" : "다른 제품들"}`}
            titlecard1={`${
              language === "ENG" ? "AI Vision Features" : "AI Vision 기능"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "AI BOX System Diagram"
                : "AI BOX 시스템 구성도"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "AI Face Recognition Solution"
                : "AI 안면인식 솔루션"
            }`}
            image1={OtherProduct1}
            image2={OtherProduct2}
            image3={OtherProduct3}
            href1={"/ai-vision"}
            href2={"/ai-box"}
            href3={"/ai-face-recognition"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
