import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundProductIntroduction from "../../Assets/header background product introduction.png";
import Footer from "../../Components/Footer";
import AiBoxImg from "../../Assets/ai box system.png";
import AiBoxKORImg from "../../Assets/ai box system KOR.png";
import OtherProduct1 from "../../Assets/AI Face Other.png";
import OtherProduct2 from "../../Assets/AI Fire Other.png";
import OtherProduct3 from "../../Assets/AI Abnormal Behavior Other.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function AiBoxSystemDiagram() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={2} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Product Introduction" : "제품 소개"
        }`}
        subtitle={`${
          language === "ENG" ? "AI BOX System Diagram" : "AI BOX 시스템 구성도"
        }`}
        background={BackgroundProductIntroduction}
        colorBorder={"#32a7df"}
      />
      <div className="bg-[#fefefe] pb-20 pt-16 flex flex-row items-start w-fit">
        <LeftMenu currentChoose={0} currentBold={"AI BOX System Diagram"} />
        <div className="flex flex-col px-0 sm:px-8">
          <div className="flex flex-col">
            <div className="w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden">
              <img
                src={language === "ENG" ? AiBoxImg : AiBoxKORImg}
                alt="AI BOX System"
                onContextMenu={(e) => e.preventDefault()}
                className="min-w-[700px] sm:min-w-0 sm:w-full px-8"
                data-aos="flip-up"
                data-aos-duration="750"
              />
            </div>
          </div>
          <OtherContent
            title={`${language === "ENG" ? "Other Products" : "다른 제품들"}`}
            titlecard1={`${
              language === "ENG"
                ? "AI Face Recognition Solution"
                : "AI 안면인식 솔루션"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "AI Fire Detection Solution"
                : "AI 화재감지 솔루션"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "AI Abnormal Behavior Detection Solution"
                : "AI 이상행동감지 솔루션"
            }`}
            image1={OtherProduct1}
            image2={OtherProduct2}
            image3={OtherProduct3}
            href1={"/ai-face-recognition"}
            href2={"/ai-fire-detection"}
            href3={"/ai-abnormal-behavior"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
