import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundSolutionApplication from "../../Assets/header background solution application.png";
import Footer from "../../Components/Footer";
import SaLargeMart1 from "../../Assets/sa large mart 1.png";
import SaLargeMart1KOR from "../../Assets/sa large mart 1 KOR.png";
import SaLargeMart2 from "../../Assets/sa large mart 2.svg";
import SaLargeMart2KOR from "../../Assets/sa large mart 2 KOR.svg";
import SaLargeMart3 from "../../Assets/sa large mart 3.png";
import SaLargeMart3KOR from "../../Assets/sa large mart 3 KOR.png";
import OtherSolutions1 from "../../Assets/App Solution 3.png";
import OtherSolutions2 from "../../Assets/App Solution 4.png";
import OtherSolutions3 from "../../Assets/App Solution 5.png";
import LeftMenu from "../../Components/LeftMenu";
import OtherContent from "../../Components/OtherContent";
import HeaderContent from "../../Components/HeaderContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function SaLargeMart() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={3} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Solution Applications" : "솔루션 활용분야"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Large Mart Shopping Mall Security"
            : "대형마트/쇼핑몰 보안/화재/안전관리"
        }`}
        background={BackgroundSolutionApplication}
        colorBorder={"#f48e35"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start w-fit">
        <LeftMenu
          currentChoose={1}
          currentBold={"Large Mart Shopping Mall Security"}
        />
        <div className="flex flex-col px-0 sm:px-8">
          <div className="flex flex-col">
            <h2 className="mx-8 font-bold text-3xl lg:text-4xl mb-8">
              {language === "ENG"
                ? "Supervised and Self-Learning AI Management"
                : "감독 및 자기 학습 인공 지능 관리"}
            </h2>
            <div className="w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden">
              <img
                src={language === "ENG" ? SaLargeMart1 : SaLargeMart1KOR}
                alt="Sa large mart 1"
                onContextMenu={(e) => e.preventDefault()}
                className="min-w-[700px] sm:min-w-0 sm:w-full mt-12 px-8 self-center"
                data-aos="fade-down"
                data-aos-duration="750"
              />
            </div>
            <div
              className="mx-auto mt-10 mb-12 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 pt-10 sm:mt-12 sm:pt-12 lg:mx-0 lg:max-w-none lg:grid-cols-3"
              data-aos="zoom-in-up"
              data-aos-duration="750"
              data-aos-anchor-placement="top-center"
            >
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Storage Room" : "보관실"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG"
                      ? "Access Control without Access Card"
                      : "출입카드 없는 출입 통제"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Management Room" : "관리실"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG"
                      ? "Customer Flow Analytics"
                      : "고객 흐름 분석"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Customer Demographics"
                      : "고객 인구 통계"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Merchandise Management"
                      : "상품 관리"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Parking Lot" : "주차장"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG" ? "Illegal Parking" : "불법 주차"}
                  </li>
                  <li>{language === "ENG" ? "Vehicle Damage" : "차량 파손"}</li>
                  <li>{language === "ENG" ? "Vehicle Theft" : "차량 도난"}</li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Merchandise Shelves" : "상품 진열대"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG"
                      ? "Merchandise Management"
                      : "상품 관리"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Abnormal Behavior Monitoring"
                      : "이상행동 감시"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Know Your Customers Flow"
                      : "고객 동선 파악"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Cashier Counter" : "계산대 카운터"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG" ? "Queue Management" : "대기열 관리"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Abnormal Behavior Monitoring"
                      : "이상행동 감시"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Submission of Customer Dispute Evidence"
                      : "고객 분쟁 증거 제출"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Entrance" : "입구"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG"
                      ? "Counting the Number of People"
                      : "인원 계산"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "VIP & Thief Recognition"
                      : "VIP 및 도둑 인식"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Customer Demographics"
                      : "고객 인구 통계"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-[#1a1a1a] pt-12 pb-20 md:px-8 px-4">
            <img
              src={language === "ENG" ? SaLargeMart3 : SaLargeMart3KOR}
              alt="Sa large mart 3"
              onContextMenu={(e) => e.preventDefault()}
              className="w-full lg:w-[80%] mx-auto"
              data-aos="flip-left"
              data-aos-duration="750"
              data-aos-anchor-placement="top-center"
            />
          </div>
          <div className="mt-12 md:mt-20 mx-8">
            <img
              src={language === "ENG" ? SaLargeMart2 : SaLargeMart2KOR}
              alt="Sa large mart 2"
              onContextMenu={(e) => e.preventDefault()}
              className="w-full lg:w-[75%] mx-auto"
              data-aos="flip-right"
              data-aos-duration="750"
              data-aos-anchor-placement="top-center"
            />
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Solution Area" : "기타 솔루션 영역"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Public Security Social Safety Net"
                : "공공 방범/치안/사회안전망"
            }`}
            titlecard2={`${
              language === "ENG" ? "Bank Security" : "은행 방범/보이스피싱/안전"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Shopping Malls Security Facilities Crime Prevention"
                : "상가/보안시설 방범/치안"
            }`}
            image1={OtherSolutions1}
            image2={OtherSolutions2}
            image3={OtherSolutions3}
            href1={"/sa-public-security"}
            href2={"/sa-bank-security"}
            href3={"/sa-shopping-malls"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
