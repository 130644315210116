import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundSolutionApplication from "../../Assets/header background solution application.png";
import Footer from "../../Components/Footer";
import SaManufacturing1 from "../../Assets/sa manufacturing 1.png";
import OtherSolutions1 from "../../Assets/App Solution 8.png";
import OtherSolutions2 from "../../Assets/App Solution 1.png";
import OtherSolutions3 from "../../Assets/App Solution 2.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function SaManufacturing() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={3} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Solution Applications" : "솔루션 활용분야"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Manufacturing Quality Control (QC)"
            : "제조업 품질관리(QC)"
        }`}
        background={BackgroundSolutionApplication}
        colorBorder={"#f48e35"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start">
        <LeftMenu
          currentChoose={1}
          currentBold={"Manufacturing Quality Control (QC)"}
        />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8">
            <h2 className="font-bold text-3xl lg:text-4xl mb-8">
              {language === "ENG"
                ? "Improving the Accuracy of Quality Control Process"
                : "품질 관리 프로세스의 정확성 향상"}
            </h2>
            <p
              className="font-normal text-xl text-justify"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "AFD-NN is one of the deep learning technologies for face detection and recognition. AFD-NN has high accuracy compared to other deep learning models."
                : "AFD-NN은 얼굴 감지 및 인식을 위한 딥러닝 기술 중 하나이며 AFD-NN은 다른 딥러닝 모델들과 비교하여 정확도가 높다."}
            </p>
            <img
              src={SaManufacturing1}
              alt="Sa Manufacturing 1"
              onContextMenu={(e) => e.preventDefault()}
              className="w-full self-center my-16"
              data-aos="flip-left"
              data-aos-duration="750"
            />
            <div
              className="flex flex-col space-y-4 mb-12 border-2 border-black rounded-3xl p-8"
              data-aos="flip-left"
              data-aos-duration="750"
            >
              <p className="text-xl text-justify mb-4">
                {language === "ENG"
                  ? "In existing industrial sites, it is possible to determine whether there is a defect in the product. Relying on individual workers to move forward. This varies from moment to moment depending on the individual’s level of fatigue and physical and mental state."
                  : "기존 산업 현장에서는 생산품의 불량 유무 판별을 작업자 개개인에게 의존하여 진행 이는 개인의 피로도, 신체나 정신 상태에 따라 매순간 달라진다."}
              </p>
              <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 justify-between items-stretch">
                <div className="flex justify-center items-center bg-[#1a1a1a] w-full lg:w-1/2 p-4 rounded-3xl text-center text-white">
                  <p>
                    {language === "ENG"
                      ? "Reliabilities issues"
                      : "신뢰성 문제"}
                  </p>
                </div>
                <div className="flex justify-center items-center bg-[#1a1a1a] w-full lg:w-1/2 p-4 rounded-3xl text-center text-white">
                  <p>
                    {language === "ENG"
                      ? "According to proficiency level individual error"
                      : "숙련도에 따른 개개인 오차"}
                  </p>
                </div>
                <div className="flex justify-center items-center bg-[#1a1a1a] w-full lg:w-1/2 p-4 rounded-3xl text-center text-white">
                  <p>
                    {language === "ENG"
                      ? "Real-time inspection or total inspection if it's difficult"
                      : "실시간 검사나 전수 검사가 어려운 경우"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#1a1a1a] py-20">
            <div className="mx-8" data-aos="flip-right" data-aos-duration="750">
              <div className="flex flex-col space-y-4 mb-12 border-2 border-[#fefefe] rounded-3xl p-8">
                <p className="text-xl text-justify text-white mb-4">
                  {language === "ENG"
                    ? "In settings where mass production and high quality are required, "
                    : "대량 생산과 높은 품질이 요구되는 환경에서는, "}
                  <b>
                    {language === "ENG"
                      ? "the fast processing speed and accuracy of AERY Computer Vision "
                      : "AERY Computer Vision의 빠른 처리속도와 정확성 "}
                  </b>
                  {language === "ENG"
                    ? "complement human visual capabilities and enhance productivity."
                    : "인간의 시각적 능력을 보완하고 생산성을 향상시킵니다."}
                </p>
                <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 justify-between items-stretch">
                  <div className="flex justify-center items-center bg-[#fefefe] w-full lg:w-1/2 p-4 rounded-3xl text-center text-[#1a1a1a]">
                    <p>
                      {language === "ENG"
                        ? "Production efficiency increase"
                        : "생산 효율 증가"}
                    </p>
                  </div>
                  <div className="flex justify-center items-center bg-[#fefefe] w-full lg:w-1/2 p-4 rounded-3xl text-center text-[#1a1a1a]">
                    <p>
                      {language === "ENG"
                        ? "Cost reduction effect"
                        : "원가 절감 효과"}
                    </p>
                  </div>
                  <div className="flex justify-center items-center bg-[#fefefe] w-full lg:w-1/2 p-4 rounded-3xl text-center text-[#1a1a1a]">
                    <p>
                      {language === "ENG"
                        ? "Utilization of manpower flexible"
                        : "인력 활용의 유연화"}
                    </p>
                  </div>
                  <div className="flex justify-center items-center bg-[#fefefe] w-full lg:w-1/2 p-4 rounded-3xl text-center text-[#1a1a1a]">
                    <p>
                      {language === "ENG"
                        ? "Strengthening competitiveness effect"
                        : "경쟁력 강화의 효과"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Solution Area" : "기타 솔루션 영역"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Prevention of Early Detection of Natural Disasters"
                : "자연재해/화재/안전 조기감지 예방"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Apartment Security Management"
                : "아파트 보안/화재/안전관리"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Large Mart Shopping Mall Security"
                : "대형마트/쇼핑몰 보안/화재/안전관리"
            }`}
            image1={OtherSolutions1}
            image2={OtherSolutions2}
            image3={OtherSolutions3}
            href1={"/sa-prevention-disasters"}
            href2={"/sa-apartment-security"}
            href3={"/sa-large-mart"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
