import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundProductIntroduction from "../../Assets/header background product introduction.png";
import Footer from "../../Components/Footer";
import AiFace1 from "../../Assets/ai face 1.png";
import AiFace2 from "../../Assets/ai face 2.png";
import AiFace3 from "../../Assets/ai face 3.png";
import AiFace4 from "../../Assets/ai face 4.png";
import LogoAery from "../../Assets/Logo Aery.png";
import OtherProduct1 from "../../Assets/AI Fire Other.png";
import OtherProduct2 from "../../Assets/AI Abnormal Behavior Other.png";
import OtherProduct3 from "../../Assets/AI Object Tracking Other.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function AiFaceRecog() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={2} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Product Introduction" : "제품 소개"
        }`}
        subtitle={`${
          language === "ENG"
            ? "AI Face Recognition Solution"
            : "AI 안면인식 솔루션"
        }`}
        background={BackgroundProductIntroduction}
        colorBorder={"#32a7df"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start">
        <LeftMenu
          currentChoose={0}
          currentBold={"AI Face Recognition Solution"}
        />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8">
            <h2 className="font-bold text-3xl mb-8">
              {language === "ENG"
                ? "Why Our Face Recognition is the Best Solution"
                : "우리 얼굴 인식 기술이 최고의 솔루션인 이유는 무엇인가요?"}
            </h2>
            <div
              className="flex flex-col lg:flex-row bg-black justify-center items-center mx-auto"
              data-aos="flip-up"
              data-aos-duration="750"
            >
              <img
                src={AiFace1}
                alt="AI Face 1"
                onContextMenu={(e) => e.preventDefault()}
                className="w-full lg:w-1/2"
                data-aos="fade-left"
                data-aos-duration="750"
              />
              <div
                className="flex flex-col p-8"
                data-aos="fade-right"
                data-aos-duration="750"
              >
                <h2 className="text-white text-center lg:text-xl xl:text-3xl">
                  {language === "ENG"
                    ? "The world's highest Face Recognition rate of "
                    : "세계 최고 수준 안면인식률 "}
                  <span className="text-[#36c60a]">99.9%</span>
                </h2>
                <span className="text-[#fcd346] text-center lg:text-base xl:text-xl mt-2">
                  {language === "ENG"
                    ? `"When you're caught, you'll be hit"`
                    : '"찍히면 잡힌다."'}
                </span>
              </div>
            </div>
            <div className="flex flex-col-reverse items-center lg:flex-row my-12 lg:space-x-8">
              <div
                className="flex flex-col"
                data-aos="fade-down"
                data-aos-duration="750"
              >
                <h1 className="text-black font-bold text-3xl mb-8 text-center lg:text-left">
                  {language === "ENG"
                    ? "Amazing Precision Technology"
                    : "놀라운 정확성과 기술력"}
                </h1>
                <p className="text-black text-xl text-justify mb-4">
                  {language === "ENG"
                    ? "Based on Aery's self-developed neural network, the accuracy is 99.9% (LFW, Labeled Faces in the Wild), KISA (Korea Internet & Security Agency) It is the world's best AI engine for face recognition that has obtained AI face recognition bio certification."
                    : "에어리의 자체 개발된 신경망을 기반으로 한 얼굴 인식 엔진은 정확도가 99.9%입니다 (LFW, Labeled Faces in the Wild). KISA (한국인터넷진흥원)에서는 이 얼굴 인식 기술이 AI 얼굴 인식 바이오 인증을 획득한 세계 최고 수준의 AI 엔진입니다."}
                </p>
                <p className="text-black text-xl text-justify">
                  {language === "ENG"
                    ? "Even when wearing a mask, the user’s Identify with 98% accuracy and Anti-spoofing technology provides high safety and Ensure accurate biometric authentication"
                    : "마스크를 착용하더라도 사용자의 신원을 98%의 정확도로 식별하며 안티 스푸핑 기술은 높은 안전성을 제공하고 정확한 생체 인증을 보장합니다."}
                </p>
              </div>
              <img
                src={AiFace2}
                alt="AI Face 2"
                onContextMenu={(e) => e.preventDefault()}
                className="w-[80%] lg:w-2/5 mb-4 lg:mb-0"
                data-aos="fade-up"
                data-aos-duration="750"
              />
            </div>
          </div>
          <div className="bg-[#1a1a1a] p-8 py-16">
            <div className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-8 mx-8">
              <div
                className="flex flex-col justify-center items-center text-[#fefefe] font-medium text-2xl space-y-4"
                data-aos="zoom-in-down"
                data-aos-duration="750"
              >
                <img
                  src={AiFace3}
                  alt="AI Face 3"
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-full sm:w-[80%]"
                />
                <span className="text-lg 2xl:text-2xl text-center px-4 py-2 border border-white rounded-full">
                  {language === "ENG"
                    ? "Mask Face Recognition"
                    : "마스크 얼굴 인식"}
                </span>
              </div>
              <div
                className="flex flex-col justify-center items-center text-[#fefefe] font-medium text-2xl space-y-4"
                data-aos="zoom-in-down"
                data-aos-duration="750"
              >
                <img
                  src={AiFace4}
                  alt="AI Face 4"
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-full sm:w-[80%] flex-1"
                />
                <span className="text-lg 2xl:text-2xl text-center px-4 py-2 border border-white rounded-full">
                  {language === "ENG" ? "Spoofing Prevention" : "스푸핑 방지"}
                </span>
              </div>
            </div>
          </div>
          <div className="mx-8">
            <h1
              className="font-bold text-black text-4xl my-12"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "Our Technology Advantages"
                : "당사의 기술 이점"}
            </h1>
            <div className="sm:hidden flex flex-col space-y-1 text-[#fefefe] text-lg">
              <div className="bg-[#1a1a1a] p-4 text-center font-bold text-xl sm:text-2xl lg:text-3xl">
                <span>
                  {language === "ENG" ? "People's Republic of China" : "중국"}
                </span>
              </div>
              <div className="bg-[#1a1a1a] p-4 text-left lg:text-justify align-top">
                <p>
                  {language === "ENG"
                    ? "The face recognition technology in China is known to be at the world's highest level. Within China, face recognition technology is already widely used in various fields such as security systems, automatic payments, and traffic law enforcement."
                    : "중국의 안면인식 기술은 세계 최고 수준이라고 알려져 있다. 중국 내에선 이미 보안시스템, 자동결제, 교통법규 위반 단속 등 다양한 분야에서 안면인식 기술이 널리 상용화"}
                </p>
                <p className="self-center text-center">
                  {language === "ENG"
                    ? "Face Recognition accuracy is 98%"
                    : "안면인식 정확도 98%"}
                </p>
              </div>
            </div>
            <div className="sm:hidden flex flex-col space-y-1 text-[#fefefe] text-lg mt-4">
              <div className="bg-[#192d4b] p-4 text-center font-bold text-lg sm:text-2xl lg:text-3xl">
                <img
                  src={LogoAery}
                  alt="Logo AERY"
                  onContextMenu={(e) => e.preventDefault()}
                  className="max-w-[60%] lg:w-1/2 mx-auto"
                />
              </div>
              <div className="bg-[#192d4b] p-4 text-left lg:text-justify align-top">
                <p>
                  {language === "ENG"
                    ? "AI Face Recognition Bio Obtained KISA Bio Certification ※ KISA (Korea Internet & Security Agency) AI Face Recognition with AF-CNN Neural Network Developed In-House"
                    : "AI 안면인식 바이오 KISA 바이오 인증 획득 ※ KISA(한국인터넷진흥원) AI 안면인식 AF-CNN 신경망 자체개발"}
                </p>
                <p className="!text-[#fcd436] my-8 font-bold">
                  {language === "ENG"
                    ? "Face Recognition Accuracy: 99.9%"
                    : "안면인식 정확도 99.9%"}
                </p>
                <p className="mb-8">
                  {language === "ENG"
                    ? "Accuracy of Face Recognition with Hats and Masks: 98%"
                    : "모자, 마스크 착용시 안면인식 정확도 98%"}
                </p>
                <p>
                  {language === "ENG"
                    ? "Evaluated as the best engine in face recognition, also face recognition bio certification."
                    : "AI안면인식 바이오 인증까지 획득한 안면인식 최고의 엔진으로 평가"}
                </p>
              </div>
            </div>
            <table
              className="table-fixed hidden sm:block"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <tbody>
                <tr className="align-middle bg-[#1a1a1a] text-[#fefefe] text-lg border-b-4">
                  <td className="border-r-4 p-4 w-1/2 text-center font-bold text-xl sm:text-2xl lg:text-3xl">
                    {language === "ENG" ? "People's Republic of China" : "중국"}
                  </td>
                  <td className="p-4 w-1/2 bg-[#192d4b]">
                    <img
                      src={LogoAery}
                      alt="Logo AERY"
                      onContextMenu={(e) => e.preventDefault()}
                      className="w-full md:w-1/2 mx-auto"
                    />
                  </td>
                </tr>
                <tr className="align-middle bg-[#1a1a1a] text-[#fefefe] text-base lg:text-lg">
                  <td className="border-r-4 p-4 w-1/2 text-left lg:text-justify align-top">
                    <p>
                      {language === "ENG"
                        ? "The face recognition technology in China is known to be at the world's highest level. Within China, face recognition technology is already widely used in various fields such as security systems, automatic payments, and traffic law enforcement."
                        : "중국의 안면인식 기술은 세계 최고 수준이라고 알려져 있다. 중국 내에선 이미 보안시스템, 자동결제, 교통법규 위반 단속 등 다양한 분야에서 안면인식 기술이 널리 상용화"}
                    </p>
                    <p>
                      {language === "ENG"
                        ? "Face Recognition accuracy is 98%"
                        : "안면인식 정확도 98%"}
                    </p>
                  </td>
                  <td className="p-4 w-1/2 bg-[#192d4b] text-left lg:text-center align-top">
                    <p>
                      {language === "ENG"
                        ? "AI Face Recognition Bio Obtained KISA Bio Certification ※ KISA (Korea Internet & Security Agency) AI Face Recognition with AF-CNN Neural Network Developed In-House"
                        : "AI 안면인식 바이오 KISA 바이오 인증 획득 ※ KISA(한국인터넷진흥원) AI 안면인식 AF-CNN 신경망 자체개발"}
                    </p>
                    <p className="!text-[#fcd436] my-8 font-bold">
                      {language === "ENG"
                        ? "Face Recognition Accuracy: 99.9%"
                        : "안면인식 정확도 99.9%"}
                    </p>
                    <p className="mb-8">
                      {language === "ENG"
                        ? "Accuracy of Face Recognition with Hats and Masks: 98%"
                        : "모자, 마스크 착용시 안면인식 정확도 98%"}
                    </p>
                    <p>
                      {language === "ENG"
                        ? "Evaluated as the best engine in face recognition, also face recognition bio certification."
                        : "AI안면인식 바이오 인증까지 획득한 안면인식 최고의 엔진으로 평가"}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              className="bg-[#1a1a1a] text-center w-full mt-4 p-4"
              data-aos="fade-up"
              data-aos-duration="750"
            >
              <p className="mx-auto text-white text-lg w-3/4">
                {language === "ENG"
                  ? "The difference in face recognition accuracy of nearly 2% is as large as the result value of missing 2 out of 100 criminals."
                  : "안면인식 정확도의 2% 가까이 차이는 100명중 2명의 범인을 놓치는 결과 값이 나오는 만큼 정확도의 기술력 차이는 크다."}
              </p>
            </div>
          </div>
          <OtherContent
            title={`${language === "ENG" ? "Other Products" : "다른 제품들"}`}
            titlecard1={`${
              language === "ENG"
                ? "AI Fire Detection Solution"
                : "AI 화재감지 솔루션"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "AI Abnormal Behavior Detection Solution"
                : "AI 이상행동감지 솔루션"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "AI Object Tracking Solution"
                : "AI 객체추적 솔루션"
            }`}
            image1={OtherProduct1}
            image2={OtherProduct2}
            image3={OtherProduct3}
            href1={"/ai-fire-detection"}
            href2={"/ai-abnormal-behavior"}
            href3={"/ai-object-tracking"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
