import React from "react";
import { useLanguage } from "../Context/LanguageContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OtherContent({
  title,
  titlecard1,
  titlecard2,
  titlecard3,
  image1,
  image2,
  image3,
  href1,
  href2,
  href3,
}) {
  const { language } = useLanguage();

  return (
    <div className={classNames("mx-8 w-fit")}>
      <h2 className="font-bold text-3xl mt-16 mb-8">{title}</h2>
      <div
        className={classNames(
          "flex flex-col space-y-8 md:space-y-8 lg:space-y-0 lg:flex-row lg:space-x-8"
        )}
      >
        <div className="border border-[#acacac] flex flex-col">
          <img
            src={image1}
            alt="Other"
            onContextMenu={(e) => e.preventDefault()}
            className={classNames("object-fill mx-auto w-full")}
          />
          <div className="flex flex-col p-8 justify-between h-full space-y-8">
            <h1 className="font-bold text-xl lg:text-base xl:text-lg flex-grow">
              {titlecard1}
            </h1>
            <a
              href={href1}
              className="hover:underline font-bold lg:text-xs xl:text-sm self-end"
            >
              {language === "ENG" ? "More Details →" : "자세한 내용은 →"}
            </a>
          </div>
        </div>
        <div className="border border-[#acacac] flex flex-col">
          <img
            src={image2}
            alt="Other"
            onContextMenu={(e) => e.preventDefault()}
            className={classNames("object-fill mx-auto w-full")}
          />
          <div className="flex flex-col p-8 justify-between h-full space-y-8">
            <h1 className="font-bold text-xl lg:text-base xl:text-lg flex-grow">
              {titlecard2}
            </h1>
            <a
              href={href2}
              className="hover:underline font-bold lg:text-xs xl:text-sm self-end"
            >
              {language === "ENG" ? "More Details →" : "자세한 내용은 →"}
            </a>
          </div>
        </div>
        <div className="border border-[#acacac] flex flex-col">
          <img
            src={image3}
            alt="Other"
            onContextMenu={(e) => e.preventDefault()}
            className={classNames("object-fill mx-auto w-full")}
          />
          <div className="flex flex-col p-8 justify-between h-full space-y-8">
            <h1 className="font-bold text-xl lg:text-base xl:text-lg flex-grow">
              {titlecard3}
            </h1>
            <a
              href={href3}
              className="hover:underline font-bold lg:text-xs xl:text-sm self-end"
            >
              {language === "ENG" ? "More Details →" : "자세한 내용은 →"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
