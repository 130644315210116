import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundProductIntroduction from "../../Assets/header background product introduction.png";
import Footer from "../../Components/Footer";
import RightArrow from "../../Assets/right-arrow 1.svg";
import AiFire1 from "../../Assets/ai fire 1.png";
import AiFire2 from "../../Assets/ai fire 2.png";
import AiFire2KOR from "../../Assets/ai fire 2 KOR.png";
import AiFire3 from "../../Assets/ai fire 3.png";
import AiFire4 from "../../Assets/ai fire 4.png";
import AiFire5 from "../../Assets/ai fire 5.png";
import AiFire5KOR from "../../Assets/ai fire 5 KOR.png";
import AiFire6 from "../../Assets/ai fire 6.svg";
import OtherProduct1 from "../../Assets/AI Abnormal Behavior Other.png";
import OtherProduct2 from "../../Assets/AI Object Tracking Other.png";
import OtherProduct3 from "../../Assets/AI Vision Other.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function AiFireDetect() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={2} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Product Introduction" : "제품 소개"
        }`}
        subtitle={`${
          language === "ENG"
            ? "AI Fire Detection Solution"
            : "AI 화재감지 솔루션"
        }`}
        background={BackgroundProductIntroduction}
        colorBorder={"#32a7df"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start">
        <LeftMenu
          currentChoose={0}
          currentBold={"AI Fire Detection Solution"}
        />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div
            className="flex flex-col mx-8"
            data-aos="fade-down"
            data-aos-duration="750"
          >
            <h2 className="font-bold text-3xl mb-8">
              {language === "ENG"
                ? "How Our Product Works"
                : "제품의 작동 방식"}
            </h2>
            <div className="flex flex-row items-start sm:items-center space-x-2 mb-8 text-justify">
              <img
                src={RightArrow}
                className="w-8 sm:w-12"
                alt="Right Arrow"
                onContextMenu={(e) => e.preventDefault()}
              />
              <span className="text-lg sm:text-xl">
                {language === "ENG"
                  ? "Developed by AI BOX method to maximize the utilization of the solution → Reuse of existing CCTV systems."
                  : "인공 지능은 일반 CCTV를 통해 비디오 입력을 분석하고 화재의 초기에 위험을 제어 센터에 통보합니다."}
              </span>
            </div>
            <div className="flex flex-row items-start sm:items-center space-x-2 mb-8 text-justify">
              <img
                src={RightArrow}
                className="w-8 sm:w-12"
                alt="Right Arrow"
                onContextMenu={(e) => e.preventDefault()}
              />
              <span className="text-lg sm:text-xl">
                {language === "ENG"
                  ? "AERY Smoke & Fire Detection Detection system has been developed to help effectively address a variety of environmental threats to human life, property and valued assets."
                  : "AERY Smoke & Fire Detection 시스템은 인간의 생명, 재산 및 소중한 자산에 대한 다양한 환경 위협에 효과적으로 대응할 수 있도록 개발되었습니다."}
              </span>
            </div>
            <div className="flex flex-row items-start sm:items-center space-x-2 mb-16 text-justify">
              <img
                src={RightArrow}
                className="w-8 sm:w-12"
                alt="Right Arrow"
                onContextMenu={(e) => e.preventDefault()}
              />
              <span className="text-lg sm:text-xl">
                {language === "ENG"
                  ? "AERY Smoke & Fire Solution solutions utilize computer vision and AI to detect a variety of hazardous environments at a distance. That’s why the system could be installed both indoors and outdoors and detect threats quicker compared to most conventional sensors."
                  : "AERY Smoke & Fire 솔루션은 컴퓨터 비전과 인공 지능을 활용하여 원격에서 다양한 위험 환경을 감지합니다. 이러한 이유로 시스템은 실내 및 실외에 모두 설치될 수 있으며 대부분의 전통적인 센서보다 빠르게 위협을 감지할 수 있습니다."}
              </span>
            </div>
          </div>
          <div className="bg-[#1a1a1a] py-12 mx-auto text-center">
            <span
              className="text-lg md:text-2xl text-white border border-white rounded-full text-center px-4 py-2 font-bold"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "AI-based Fire Detection System"
                : "AI 기반 화재감지 시스템"}
            </span>
            <div className="mx-8 my-12">
              <img
                src={AiFire1}
                className="w-full mx-auto sm:block hidden"
                alt="AI Fire 1"
                onContextMenu={(e) => e.preventDefault()}
                data-aos="flip-down"
                data-aos-duration="750"
              />
              <img
                src={AiFire6}
                className="w-[85%] mx-auto sm:hidden block"
                alt="AI Fire 6"
                onContextMenu={(e) => e.preventDefault()}
                data-aos="flip-down"
                data-aos-duration="750"
              />
            </div>
            <p
              className="mx-8 text-base md:text-xl text-white border border-white rounded-full text-center px-4 py-2"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "A function where artificial intelligence analyzes images input through general CCTV and informs the control center of danger in the early stages of a fire."
                : "일반 CCTV를 통해 입력되는 영상을 인공지능이 분석 화재 초기에 관제 센터에 위험을 알리는 기능"}
            </p>
          </div>
          <div className="my-24 mx-4 sm:my-32">
            <img
              src={language === "ENG" ? AiFire2 : AiFire2KOR}
              className="w-full"
              alt="AI Fire 2"
              onContextMenu={(e) => e.preventDefault()}
              data-aos="flip-down"
              data-aos-duration="750"
            />
          </div>
          <div className="bg-[#1a1a1a] py-12">
            <div className="mx-8">
              <h1
                className="text-2xl lg:text-3xl text-[#fefefe] border border-white py-2 text-center rounded-full"
                data-aos="fade-down"
                data-aos-duration="750"
              >
                {language === "ENG"
                  ? "Differentiation of AI-Based CCTV Fire Detection from Existing Products"
                  : "기존제품과 AI기반 CCTV화재감지 차별성"}
              </h1>
              <div
                className="flex flex-col space-y-2 mt-8 mb-4"
                data-aos="zoom-in-up"
                data-aos-duration="750"
              >
                <div className="flex flex-col sm:flex-row sm:space-x-4 justify-center items-center my-8">
                  <img
                    src={AiFire3}
                    className="w-1/3 sm:w-1/5 lg:w-1/6 mb-4 sm:mb-0"
                    alt="AI Fire 3"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                  <div className="text-[#fefefe]">
                    <h2 className="font-bold text-2xl text-center sm:text-left">
                      {language === "ENG" ? "Flame Detector" : "불꽃감지기"}
                    </h2>
                    <span className="font-light text-xl">
                      {language === "ENG"
                        ? "Sensor detection method"
                        : "센서 감지 방식"}
                    </span>
                  </div>
                </div>
                <table className="table-fixed">
                  <tbody>
                    <tr className="align-middle bg-[#444444] text-[#fefefe] text-lg border-b">
                      <td className="p-4 w-1/2 font-normal text-lg lg:text-xl">
                        {language === "ENG"
                          ? "(+) Fast detection time and high accuracy,"
                          : "(+) 감지시간이 빠르고 정확도 높음"}
                      </td>
                    </tr>
                    <tr className="align-middle bg-[#1a1a1a] text-[#fefefe] text-lg border-b">
                      <td className="p-4 w-1/2 font-normal text-lg lg:text-xl">
                        {language === "ENG"
                          ? "(-) Video surveillance is not possible limited surveillance area"
                          : "(-) 영상감시 불가 감시구역이 제한적"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="flex flex-col space-y-2 mt-8 mb-4"
                data-aos="zoom-in-up"
                data-aos-duration="750"
              >
                <div className="flex flex-col sm:flex-row sm:space-x-4 justify-center items-center my-8">
                  <img
                    src={AiFire4}
                    className="w-1/3 sm:w-1/5 lg:w-1/6 mb-4 sm:mb-0"
                    alt="AI Fire 4"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                  <div className="text-[#fefefe]">
                    <h2 className="font-bold text-2xl text-center sm:text-left">
                      {language === "ENG" ? "VMS" : "VMS"}
                    </h2>
                    <span className="font-light text-xl">
                      {language === "ENG"
                        ? "Image analysis method"
                        : "영상분석 방식"}
                    </span>
                  </div>
                </div>
                <table className="table-fixed">
                  <tbody>
                    <tr className="align-middle bg-[#444444] text-[#fefefe] text-lg border-b">
                      <td className="p-4 w-1/2 font-normal text-lg lg:text-xl">
                        {language === "ENG"
                          ? "(+) Video surveillance is possible high surveillance area"
                          : "(+) 영상 감시가 가능한 높은 감시 영역"}
                      </td>
                    </tr>
                    <tr className="align-middle bg-[#1a1a1a] text-[#fefefe] text-lg border-b">
                      <td className="p-4 w-1/2 font-normal text-lg lg:text-xl">
                        {language === "ENG"
                          ? "(-) Low accuracy and detection time 10 seconds"
                          : "(-) 정확도 낮고 감지시간 10 초"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <img
                src={language === "ENG" ? AiFire5 : AiFire5KOR}
                className="lg:w-[70%] my-12 mx-auto"
                alt="AI Fire 5"
                onContextMenu={(e) => e.preventDefault()}
                data-aos="fade-up"
                data-aos-duration="750"
              />
            </div>
          </div>
          <OtherContent
            title={`${language === "ENG" ? "Other Products" : "다른 제품들"}`}
            titlecard1={`${
              language === "ENG"
                ? "AI Abnormal Behavior Detection Solution"
                : "AI 이상행동감지 솔루션"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "AI Object Tracking Solution"
                : "AI 객체추적 솔루션"
            }`}
            titlecard3={`${
              language === "ENG" ? "AI Vision Features" : "AI Vision 기능"
            }`}
            image1={OtherProduct1}
            image2={OtherProduct2}
            image3={OtherProduct3}
            href1={"/ai-abnormal-behavior"}
            href2={"/ai-object-tracking"}
            href3={"/ai-vision"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
