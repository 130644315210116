import React from "react";
import Navbars from "../Components/Navbars";
import Footer from "../Components/Footer";
import WhyChooseUsSVG from "../Assets/Why Choose Us.svg";
import WhyChooseUsKORSVG from "../Assets/Why Choose Us KOR.svg";
import AboutUsBackground from "../Assets/background about us.png";
import AiTechnologyForImg from "../Assets/AI Technology About Us.png";
import ProfileAeryImg from "../Assets/profile aery.png";
import LogoAeryImg from "../Assets/Logo Aery.png";
import IconPhone from "../Assets/Icon Phone.svg";
import IconGlobe from "../Assets/Icon Globe.svg";
import IconEmail from "../Assets/Icon Email.svg";
import CEOimg from "../Assets/CEO.png";
import OrganizationChartBgImg from "../Assets/Organization chart background.png";
import OrganizationChart from "../Assets/Organization Chart.svg";
import ExecutiveKimImg from "../Assets/Executive KIM.png";
import ExecutiveLeeImg from "../Assets/Executive LEE.png";
import ExecutiveYuImg from "../Assets/Executive YU.png";
import Certificate1Img from "../Assets/Certificate 1.png";
import Certificate2Img from "../Assets/Certificate 2.png";
import Certificate3Img from "../Assets/Certificate 3.png";
import Certificate4Img from "../Assets/Certificate 4.png";
import ButtonUp from "../Components/ButtonUp";
import { Tab } from "@headlessui/react";
import { useLanguage } from "../Context/LanguageContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AboutUs() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={1} />
      <div className="background-container">
        <img
          src={AboutUsBackground}
          alt="Background About Us"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          className="background-image opacity-40"
        />
      </div>
      <div className="min-h-screen mx-auto max-w-2xl py-32 sm:py-32 lg:py-16 flex items-center">
        <div className="text-left mx-8">
          <h1 className="text-4xl text-white font-bold sm:text-5xl md:text-6xl tracking-tight mx-auto my-2">
            {language === "ENG"
              ? "We contribute to society by creating future"
              : "미래를 창조하여 사회에 공헌한다"}
          </h1>
          <p className="mt-8 text-lg sm:text-xl md:text-2xl text-gray-300 font-bold">
            {language === "ENG"
              ? "value based on AI technology for human safety"
              : "인간의 안전을 위한 AI 기술 기반의 가치"}
          </p>
        </div>
      </div>
      <div
        className="bg-white pt-12 sm:pt-16 xl:mx-32 lg:mx-24 md:mx-16 rounded-t-xl"
        data-aos="fade-down"
        data-aos-duration="750"
      >
        <Tab.Group>
          <Tab.List className="flex flex-col sm:flex-row mx-16 pb-16">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full py-4 text-xl border border-black font-bold",
                  selected
                    ? "bg-black text-white"
                    : "text-black hover:bg-black/[0.1] hover:underline"
                )
              }
            >
              {language === "ENG" ? "Introduction" : "소개"}
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full py-4 text-xl border border-black font-bold sm:border-l-0 border-l",
                  selected
                    ? "bg-black text-white"
                    : "text-black hover:bg-black/[0.1] hover:underline"
                )
              }
            >
              {language === "ENG" ? "Our Team" : "우리 팀"}
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full py-4 text-xl border border-black font-bold sm:border-l-0 border-l",
                  selected
                    ? "bg-black text-white"
                    : "text-black hover:bg-black/[0.1] hover:underline"
                )
              }
            >
              {language === "ENG" ? "History" : "역사"}
            </Tab>
          </Tab.List>
          <Tab.Panels className="outline-none">
            <Tab.Panel className={classNames("rounded-xl outline-none")}>
              <div
                className="bg-white flex flex-col px-2 pb-12 sm:p-10 sm:pb-24 xl:mx-32 lg:mx-24 md:mx-16"
                data-aos="fade-up"
                data-aos-duration="750"
              >
                <div className="mx-auto lg:mx-0 justify-center items-center self-center text-center space-y-10">
                  <h1 className="text-2xl font-bold tracking-tight text-black text-center sm:text-4xl md:text-5xl mb-4 self-center">
                    {language === "ENG"
                      ? "Why Choose Us"
                      : "AERY를 선택하는 이유"}
                  </h1>
                  <h2 className="text-xl font-bold tracking-tight text-black text-center sm:text-3xl md:text-4xl self-center">
                    {language === "ENG"
                      ? "Artificial Intelligence's"
                      : "인공지능의"}
                  </h2>
                  <p className="text-lg font-bold tracking-tight text-black text-center sm:text-xl md:text-2xl !mt-2 self-center">
                    {language === "ENG"
                      ? "We will make it a company that leads the future"
                      : "미래를 선도하는 기업으로 만들어 갑니다."}
                  </p>
                  <img
                    src={
                      language === "ENG" ? WhyChooseUsSVG : WhyChooseUsKORSVG
                    }
                    className="h-full w-full sm:w-3/4 md:w-5/6 lg:w-full mx-auto"
                    alt="Why Choose Us"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                  />
                </div>
              </div>
              <div className="text-white relative h-56 sm:h-full bg-white">
                <img
                  src={AiTechnologyForImg}
                  alt="Ai Technology"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  className="w-full h-full"
                  data-aos="fade-right"
                  data-aos-duration="750"
                />
                <div className="absolute top-2 right-2 sm:top-4 sm:right-4 md:top-2 lg:top-4 lg:right-8 2xl:top-20 2xl:right-32 min-[900px]:top-8">
                  <h1
                    className="xl:text-5xl lg:text-4xl font-bold sm:text-xl md:text-2xl text-base"
                    data-aos="fade-up"
                    data-aos-duration="750"
                  >
                    AI TECHNOLOGY
                  </h1>
                  <h1
                    className="xl:text-3xl lg:text-2xl sm:text-base md:text-xl tracking-wider font-bold text-sm"
                    data-aos="fade-up"
                    data-aos-duration="750"
                  >
                    ARTIFICIAL INTELLIGENCE FOR
                  </h1>
                  <div
                    className="border-l-2 border-l-white mb-8 sm:mb-4 xl:my-8 lg:mt-2 lg:mb-6"
                    data-aos="zoom-out-right"
                    data-aos-duration="750"
                  >
                    <ul className="list-none ml-2 mt-4 sm:ml-4 sm:mt-2 sm:py-2 xl:text-4xl lg:text-2xl sm:text-lg">
                      <li
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="300"
                      >
                        <b className="text-[#32a7df] tracking-widest">E</b>
                        <span className="tracking-widest font-medium">
                          nvironment
                        </span>
                      </li>
                      <li
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="600"
                      >
                        <b className="text-[#32a7df] tracking-widest">S</b>
                        <span className="tracking-widest font-medium">
                          ocial
                        </span>
                      </li>
                      <li
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="900"
                      >
                        <b className="text-[#32a7df] tracking-widest">G</b>
                        <span className="tracking-widest font-medium">
                          overnance
                        </span>
                      </li>
                    </ul>
                  </div>
                  <a
                    href="/learn-more"
                    className="border-2 py-2 px-4 font-bold sm:text-base sm:py-1 sm:px-2 lg:py-2 lg:px-4 hover:bg-white hover:text-[#1a1a1a]"
                    data-aos="fade-down"
                    data-aos-duration="1500"
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <div className="bg-white flex flex-col sm:flex-row">
                <div className="mt-16">
                  <img
                    src={LogoAeryImg}
                    alt="Logo Aery"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                    className="w-[60%] xl:w-2/5 border-2 py-3 px-5 sm:py-4 sm:px-8 rounded-3xl border-[#858585] mx-auto"
                    data-aos="zoom-in-down"
                    data-aos-duration="750"
                  />
                  <h1
                    className="font-bold sm:text-4xl text-2xl text-[#0399D4] text-center my-8 mx-8"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    World's Best A.I. company leaps into the world and creates
                    the future
                  </h1>
                  <p
                    className="font-normal text-lg sm:text-xl text-justify mt-8 mx-8"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    {language === "ENG"
                      ? "Company that prioritizes customer interests and convenient living"
                      : "고객의 이익과 편리한 생활을 우선시하는 회사"}
                  </p>
                  <p
                    className="font-normal text-lg sm:text-xl text-justify mt-8 mx-8"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    {language === "ENG"
                      ? "Since our company was founded in May 18th 2021, We are developing various software products based on artificial intelligence technology. We are also developing and researching various software products that customers want."
                      : "2021년 5월 18일에 창립된 우리 회사는 인공 지능 기술을 기반으로 다양한 소프트웨어 제품을 개발하고 있습니다. 또한 우리는 고객들이 원하는 다양한 소프트웨어 제품을 개발하고 연구하고 있습니다."}
                  </p>
                  <p
                    className="font-normal text-lg sm:text-xl text-justify mt-8 mx-8"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    {language === "ENG"
                      ? "Our goal is to foster and advance the future by developing and providing an artificial intelligence solution/service tailored to customers' lifestyles, ensuring that anyone can easily access, and utilize according to their preferences."
                      : "우리의 목표는 고객의 생활에 맞춘 인공 지능 솔루션/서비스를 개발하고 제공함으로써 누구나 쉽게 접근하고 선호에 따라 활용할 수 있도록 함으로써 미래를 육성하고 발전시키는 것입니다."}
                  </p>
                  <div
                    className="my-8 bg-[#dbdbdb] w-full h-auto py-4"
                    data-aos="fade-up"
                    data-aos-duration="750"
                  >
                    <table className="table-auto mx-8">
                      <tbody>
                        <tr className="align-top">
                          <td>
                            <p className="text-lg border-l-4 border-b border-black font-medium pl-2 pb-1 pr-12 mr-4">
                              {language === "ENG" ? "CEO" : "대표자"}
                            </p>
                          </td>
                          <td>
                            <p className="text-lg border-b border-black pb-1">
                              {language === "ENG"
                                ? "Kim Hyun-Seok"
                                : "김 현 석"}
                            </p>
                          </td>
                        </tr>
                        <tr className="align-top">
                          <td>
                            <p className="text-lg border-l-4 border-b border-black font-medium pl-2 pb-1 pr-12 mr-4">
                              {language === "ENG" ? "Capital" : "자본금"}
                            </p>
                          </td>
                          <td>
                            <p className="text-lg border-b border-black pb-1">
                              {language === "ENG"
                                ? "150,000,000 KRW"
                                : "1억 5천만원"}
                            </p>
                          </td>
                        </tr>
                        <tr className="align-top">
                          <td>
                            <p className="text-lg border-l-4 border-b border-black font-medium pl-2 pb-1 pr-12 mr-4">
                              {language === "ENG" ? "Location" : "소재지"}
                            </p>
                          </td>
                          <td>
                            <p className="text-lg border-b border-black pb-1">
                              {language === "ENG"
                                ? "Gyeonggi-do, Hwaseong-si, Dongtan Boulevard 706, Dongtan IT Valley II 1101"
                                : "경기도 화성시 동탄대로 706, 동탄IT밸리II 1101호"}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-center sm:space-x-4 mx-8 md:mx-4 pb-8"
                    data-aos="zoom-out-down"
                    data-aos-duration="750"
                  >
                    <div className="flex flex-row items-center space-x-4 lg:space-x-1">
                      <img
                        src={IconPhone}
                        alt="Icon Phone"
                        onContextMenu={(e) => e.preventDefault()}
                        draggable="false"
                        className="w-8 lg:w-1/6 h-auto"
                      />
                      <span className="text-sm lg:text-xs xl:text-sm">
                        070-4756-4545
                      </span>
                    </div>
                    <div className="flex flex-row items-center space-x-4 lg:space-x-1">
                      <img
                        src={IconGlobe}
                        alt="Icon Globe"
                        onContextMenu={(e) => e.preventDefault()}
                        draggable="false"
                        className="w-8 lg:w-1/6 h-auto"
                      />
                      <a
                        href="http://www.aery.co.kr"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm lg:text-xs xl:text-sm hover:underline hover:text-slate-500"
                      >
                        www.aery.co.kr
                      </a>
                    </div>
                    <div className="flex flex-row items-center space-x-4 lg:space-x-1">
                      <img
                        src={IconEmail}
                        alt="Icon Email"
                        onContextMenu={(e) => e.preventDefault()}
                        draggable="false"
                        className="w-8 lg:w-1/6 h-auto"
                      />
                      <a
                        href="mailto:info@aery.co.kr"
                        className="text-sm lg:text-xs xl:text-sm hover:underline hover:text-slate-500"
                      >
                        info@aery.co.kr
                      </a>
                    </div>
                  </div>
                </div>
                <img
                  src={ProfileAeryImg}
                  alt="Profile AERY"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  className="hidden lg:block md:w-1/3 lg:w-1/2 2xl:w-1/3"
                  data-aos="zoom-in-down"
                  data-aos-duration="750"
                />
              </div>
            </Tab.Panel>
            <Tab.Panel className={classNames("rounded-xl outline-none")}>
              <div
                className="bg-white px-4 py-8 sm:px-16 xl:px-24 2xl:px-32 sm:pb-32"
                data-aos="zoom-in-up"
                data-aos-duration="750"
              >
                <div className="flex flex-col md:flex-row border-black border px-4 md:px-6 sm:space-x-8 xl:px-16 lg:space-x-16">
                  <div className="flex flex-col py-16">
                    <h1
                      className="font-bold lg:text-4xl 2xl:text-5xl tracking-wide text-2xl mb-12 lg:mb-20 text-center md:text-left"
                      data-aos="zoom-in-down"
                    >
                      {language === "ENG"
                        ? "We're ready to serve you with industry-leading experience."
                        : "산업을 선도하는 경험으로 여러분을 모실 준비가 되어 있습니다."}
                    </h1>
                    <img
                      src={CEOimg}
                      className="lg:w-2/5 2xl:w-1/3 w-1/2 self-center block md:hidden mb-4"
                      alt="Example Pic"
                      onContextMenu={(e) => e.preventDefault()}
                      draggable="false"
                    />
                    <h2 className="font-bold lg:text-3xl 2xl:text-4xl text-2xl mb-2 text-center md:text-left">
                      Kim Hyun Seok Ph.D.
                    </h2>
                    <h3 className="font-bold text-[#6e6b6c] lg:text-xl 2xl:text-2xl text-lg mb-8 text-center md:text-left">
                      Chief Executive Officer
                    </h3>
                    <p className="font-regular text-justify text-xs lg:text-sm 2xl:text-lg">
                      {language === "ENG"
                        ? "AERY is a pioneering force in the world of AI and engineering, led by our renowned founder Kim Hyun Seok Ph.D. graduated from the Graduate School of Kyungpook National University and Adjunct Professor at Sooncheonhyang University. Kim Hyun Seok has an impressive career history, including senior research roles at Samsung Electronics Wireless SW Division Group and innovative contributions to mobile technology in Korea, such as the development of the SKT/KT/LG Data Service Protocol and the introduction of the N Top mobile browser."
                        : "AERY는 인공 지능 및 공학 분야에서의 선도적인 역할을 하는데, 이는 우리 명성 높은 창립자 김현석 박사의 리더십 아래 이루어지고 있습니다. 김현석 박사는 경북대학교 대학원에서 학위를 취득하였으며 순천향대학교에서 부교수로 재직하고 있습니다. 김현석은 삼성전자 무선 SW 부문에서 고위 연구 직책을 맡은 경력과 함께 대한민국에서의 모바일 기술에 혁신적인 기여를 하였습니다. SKT/KT/LG 데이터 서비스 프로토콜 개발 및 N Top 모바일 브라우저 도입과 같은 일들이 그의 인상적인 경력에 포함되어 있습니다."}
                    </p>
                    <br />
                    <p className="font-regular text-justify text-xs lg:text-sm 2xl:text-lg">
                      {language === "ENG"
                        ? "With a strong commitment to research, Kim Hyun Seok has published influential papers and received the prestigious 2019 Jang Young-sil Award. Aery.Co.,Ltd is further supported by a team of experts with extensive industry experience, including three Doctor of Engineering (Ph.D.) professionals, two engineers, five master's students, and 15 engineering students, for a total of 25 researchers dedicated to pushing the boundaries of the industry technology."
                        : "김현석은 연구에 대한 강한 헌신을 바탕으로 영향력 있는 논문을 발표하였으며, 2019년 장영실상을 수상한 바 있습니다. Aery.Co.,Ltd는 뛰어난 산업 경험을 지닌 전문가 팀의 지원을 받고 있습니다. 세 명의 공학박사 전문가, 두 명의 엔지니어, 다섯 명의 석사과정 학생, 그리고 15 명의 공학 학생으로 구성된 이 팀은 업계 기술의 경계를 넓히기 위해 헌신하고 있는 총 25 명의 연구원으로 이루어져 있습니다."}
                    </p>
                  </div>
                  <img
                    src={CEOimg}
                    className="2xl:w-[40%] xl:w-[50%] lg:w-2/5 w-1/5 sm:w-1/2 self-end sm:self-center sm:border-b-2 2xl:border-0 border-gray-800 2xl:self-end hidden md:block"
                    alt="Example Pic"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                  />
                </div>
              </div>
              <div
                className="background-organization py-12 sm:py-16"
                style={{ backgroundImage: `url(${OrganizationChartBgImg})` }}
                data-aos="fade-up"
                data-aos-duration="750"
              >
                <h1 className="underline text-white font-bold text-3xl sm:text-4xl xl:text-5xl px-8">
                  Organization Chart
                </h1>
                <div className="max-w-full overflow-x-scroll sm:overflow-x-hidden">
                  <img
                    src={OrganizationChart}
                    alt="Organization Chart"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                    className="min-w-[800px] sm:min-w-0 sm:w-full md:w-full lg:w-[90%] xl:w-3/4 p-4 sm:p-8 pt-8 mx-auto"
                  />
                </div>
              </div>
              <div className="bg-[#d9d9d9] flex flex-col items-center py-16 px-8 md:py-20 md:px-12 lg:py-24 lg:px-16">
                <h1
                  className="font-bold text-3xl sm:text-4xl xl:text-5xl text-center"
                  data-aos="fade-down"
                  data-aos-duration="750"
                >
                  Executive Team
                </h1>
                <div className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row mt-12 lg:mt-16">
                  <div
                    className="bg-[#fefefe] rounded-3xl flex flex-col p-8 justify-start flex-1 lg:hidden"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    <img
                      src={ExecutiveKimImg}
                      alt="Ex-1"
                      onContextMenu={(e) => e.preventDefault()}
                      draggable="false"
                      className="lg:max-w-full lg:min-w-[80%] md:max-w-[35%] sm:max-w-[30%] max-w-[45%] object-cover mx-auto rounded-xl"
                    />
                    <div>
                      <h2 className="font-bold text-xl mt-4 text-center lg:text-left">
                        Kim Hyun-Seok Ph.D.
                      </h2>
                      <h3 className="font-bold text-[#6e6b6c] text-base text-center lg:text-left">
                        Chief Executive Officer
                      </h3>
                      <p className="text-start mt-4 text-sm">
                        {language === "ENG"
                          ? "Kim Hyun-Seok Ph.D., our CEO, is an industry leader in mobile software engineering with a significant career at Samsung. His expertise in Mobile Platform Software Engineering and AI Algorithm Engineering has been instrumental in advancing mobile software, driving our company's commitment to innovation in AI and mobile software solutions."
                          : "김현석 박사, 당사의 최고 경영자로서 삼성에서의 중요한 경력을 가진 이동 소프트웨어 엔지니어링 분야의 산업 리더입니다. 그의 이동 플랫폼 소프트웨어 엔지니어링과 AI 알고리즘 엔지니어링에 대한 전문 지식은 모바일 소프트웨어의 발전에 기여하였으며, 당사의 AI 및 모바일 소프트웨어 솔루션에 대한 혁신에 대한 헌신을 견인하고 있습니다."}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-[#fefefe] rounded-3xl flex flex-col p-8 justify-start flex-1"
                    data-aos="fade-up"
                    data-aos-duration="750"
                  >
                    <img
                      src={ExecutiveLeeImg}
                      alt="Ex-2"
                      onContextMenu={(e) => e.preventDefault()}
                      draggable="false"
                      className="lg:max-w-full lg:min-w-[80%] md:max-w-[35%] sm:max-w-[30%] max-w-[45%] object-cover mx-auto rounded-xl"
                    />
                    <div>
                      <h2 className="font-bold text-xl mt-4 text-center lg:text-left">
                        Lee Sung-hoon Ph.D.
                      </h2>
                      <h3 className="font-bold text-[#6e6b6c] text-base text-center lg:text-left">
                        Chief Operating Officer
                      </h3>
                      <p className="text-start mt-4 text-sm">
                        {language === "ENG"
                          ? "Lee Sung-hoon Ph.D., our Chief Operating Officer, is an accomplished professional with extensive experience in electronic circuit board and electronic device development. As a Special Assignment Professor at the HRDe Institute of Continuing Education, his expertise greatly contributes to our commitment to innovation and excellence in IT product development."
                          : "이 성훈 박사는 당사의 최고운영책임자로서 전자 회로 기판 및 전자 기기 개발 분야에서 폭넓은 경험을 가진 숙련된 전문가입니다. HRDe 지속교육 연구소의 특임 교수로서, 그의 전문지식은 당사의 IT 제품 개발에서 혁신과 탁월함에 대한 약속에 큰 기여를 하고 있습니다."}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-[#fefefe] rounded-3xl lg:flex flex-col p-8 justify-start flex-1 hidden lg:ml-8"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    <img
                      src={ExecutiveKimImg}
                      alt="Ex-1"
                      onContextMenu={(e) => e.preventDefault()}
                      draggable="false"
                      className="lg:max-w-full lg:min-w-[80%] md:max-w-[35%] sm:max-w-[30%] max-w-[45%] object-cover mx-auto rounded-xl"
                    />
                    <div>
                      <h2 className="font-bold text-xl mt-4 text-center lg:text-left">
                        Kim Hyun-Seok Ph.D.
                      </h2>
                      <h3 className="font-bold text-[#6e6b6c] text-base text-center lg:text-left">
                        Chief Executive Officer
                      </h3>
                      <p className="text-start mt-4 text-sm">
                        {language === "ENG"
                          ? "Kim Hyun-Seok Ph.D., our CEO, is an industry leader in mobile software engineering with a significant career at Samsung. His expertise in Mobile Platform Software Engineering and AI Algorithm Engineering has been instrumental in advancing mobile software, driving our company's commitment to innovation in AI and mobile software solutions."
                          : "김현석 박사, 당사의 최고 경영자로서 삼성에서의 중요한 경력을 가진 이동 소프트웨어 엔지니어링 분야의 산업 리더입니다. 그의 이동 플랫폼 소프트웨어 엔지니어링과 AI 알고리즘 엔지니어링에 대한 전문 지식은 모바일 소프트웨어의 발전에 기여하였으며, 당사의 AI 및 모바일 소프트웨어 솔루션에 대한 혁신에 대한 헌신을 견인하고 있습니다."}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-[#fefefe] rounded-3xl flex flex-col p-8 justify-start flex-1 lg:ml-8"
                    data-aos="fade-up"
                    data-aos-duration="750"
                  >
                    <img
                      src={ExecutiveYuImg}
                      alt="Ex-3"
                      onContextMenu={(e) => e.preventDefault()}
                      draggable="false"
                      className="lg:max-w-full lg:min-w-[80%] md:max-w-[35%] sm:max-w-[30%] max-w-[45%] object-cover mx-auto rounded-xl"
                    />
                    <div>
                      <h2 className="font-bold text-xl mt-4 text-center lg:text-left">
                        Yu Yang-jun
                      </h2>
                      <h3 className="font-bold text-[#6e6b6c] text-base text-center lg:text-left">
                        Chief Technology Officer
                      </h3>
                      <p className="text-start mt-4 text-sm">
                        {language === "ENG"
                          ? "Yu Yang-jun, our Chief Technology Officer, is a pioneering force in GAN Tech and Matching Algorithm, Reinforcement AI Algorithm, Image Processing, SW Engineering, and System SW Engineering. With his expertise and leadership, we drive innovation and excellence in technology solutions, setting new industry standards."
                          : "유양준, 당사의 최고 기술 책임자는 GAN 기술 및 매칭 알고리즘, 강화 학습 AI 알고리즘, 이미지 처리, 소프트웨어 엔지니어링 및 시스템 소프트웨어 엔지니어링 분야에서 개척적인 역할을 하고 있습니다. 그의 전문 지식과 리더십으로 우리는 기술 솔루션에서 혁신과 탁월함을 추진하며 새로운 산업 표준을 제시하고 있습니다."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel className={classNames("rounded-xl outline-none")}>
              <div className="bg-[#1a1a1a] flex flex-col items-center p-8 sm:p-16">
                <h1
                  className="font-bold text-center sm:text-5xl text-3xl text-[#fefefe]"
                  data-aos="fade-down"
                  data-aos-duration="750"
                >
                  {language === "ENG" ? "Company History" : "회사연혁"}
                </h1>
                <div className="flex flex-col space-y-8 text-white my-12 w-full">
                  <div
                    className="flex flex-row justify-between items-start"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    <div className="border-t-4 border-white w-[20%]">
                      <h1 className="text-start mt-4 font-bold text-lg sm:text-2xl">
                        ~2021
                      </h1>
                    </div>
                    <ul className="list-disc ml-2 sm:ml-4 border-t-4 border-gray-400 w-[80%] text-base sm:text-xl">
                      <li className="ml-8 mt-4">
                        {language === "ENG"
                          ? "Galaxy S3 - S7 System SW & Middleware SW Development"
                          : "Galaxy S3 ~ S7 System SW & 미들웨어 SW개발"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Development of diagnostic SW OF Galaxy S7 and Samsung electric SW"
                          : "Galaxy S7 과 삼성 전장 SW의 진단 SW 개발"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Bixby SW development of Galaxy S Series - Client SW, Server Back End SW"
                          : "Galaxy S Series 의 Bixby SW 개발 - Client SW, Server Back End SW"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Developing AI Speakers for Kids - Android"
                          : "유아용 AI 스피커 개발 – Android"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Smartwatch Wireless Tester Development - Android"
                          : "스마트워치 무선 테스터 개발 – Android"}
                      </li>
                    </ul>
                  </div>
                  <div
                    className="flex flex-row justify-between items-start"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    <div className="border-t-4 border-white w-[20%]">
                      <h1 className="text-start mt-4 font-bold text-lg sm:text-2xl">
                        2022
                      </h1>
                    </div>
                    <ul className="list-disc ml-2 sm:ml-4 border-t-4 border-gray-400 w-[80%] text-base sm:text-xl">
                      <li className="ml-8 mt-4">
                        {language === "ENG"
                          ? "Development of 3D wireless protactor - F/W"
                          : "3차원 무선 각도기 개발 – F/W"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Development of AI Speaker Wireless Tester - Linux"
                          : "AI 스피커 무선 테스터 개발 – Linux"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Development of smartphone wireless tester using NFC - F/W"
                          : "NFC 를 이용한 스마트폰 무선 테스터 개발 – F/W"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Development of AI fire detection system"
                          : "AI 화재감지 시스템 개발"}
                      </li>
                    </ul>
                  </div>
                  <div
                    className="flex flex-row justify-between items-start"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    <div className="border-t-4 border-white w-[20%]">
                      <h1 className="text-start mt-4 font-bold text-lg sm:text-2xl">
                        2023
                      </h1>
                    </div>
                    <ul className="list-disc ml-2 sm:ml-4 border-t-4 border-gray-400 w-[80%] text-base sm:text-xl">
                      <li className="ml-8 mt-4">
                        {language === "ENG"
                          ? "KISA bio certification confirmed: AI facial recognition algorithm development - 99.9%"
                          : "KISA 바이오 인증 확등 : AI 안면인식 자체알고리즘 개발 – 99.9%"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Completed development of AI smartphone accident prevention APP"
                          : "AI 스마트폰 스몸비 사고방지 APP개발 완료"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "Development of AI CCTV fire detection device and patent registration"
                          : "AI CCTV 화재감지 장치 개발 및 특허 등록"}
                      </li>
                      <li className="ml-8">
                        {language === "ENG"
                          ? "K-Beauty, construction safety AI, water quality management solution under development"
                          : "K-뷰티, 건설안전 AI, 수질관리 솔루션 개발 중"}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bg-white flex flex-col items-center p-16 sm:py-32 px-8">
                <h1
                  className="font-bold text-2xl sm:text-3xl lg:text-6xl text-[#1a1a1a]"
                  data-aos="fade-down"
                  data-aos-duration="750"
                >
                  {language === "ENG"
                    ? "Certificates and Patent"
                    : "인증서 및 특허"}
                </h1>
                <table
                  className="text-center mt-8 lg:mt-12"
                  data-aos="fade-down"
                  data-aos-duration="750"
                >
                  <tbody>
                    <tr className="border-b border-slate-400">
                      <td className="p-4 bg-gray-100">
                        {language === "ENG"
                          ? "AI face recognition bio KISA (Korea Internet & Security Agency) bio certification"
                          : "AI 안면인식 바이오 KISA(한국인터넷진흥원) 바이오 인증 획득"}
                      </td>
                    </tr>
                    <tr className="border-b border-slate-400">
                      <td className="p-4">
                        {language === "ENG"
                          ? "Registered a patent for AI fire detection (fire detection method using deep learning)"
                          : "AI 화재감지 (딥러닝을 이용한 화재감지 방법) 특허 등록"}
                      </td>
                    </tr>
                    <tr className="border-b border-slate-400">
                      <td className="p-4 bg-gray-100">
                        {language === "ENG"
                          ? "Confirmation of technology venture companies and recognition of R&D department"
                          : "기술 벤처기업확인 및 연구개발 전담부서 인정"}
                      </td>
                    </tr>
                    <tr className="border-b border-slate-400">
                      <td className="p-4">
                        {language === "ENG"
                          ? "Software Business Registration (Korea Software Industry Association)"
                          : "소프트웨어 사업자 등록 (한국 소프트웨어산업 협회)"}
                      </td>
                    </tr>
                    <tr className="border-b border-slate-400">
                      <td className="p-4 bg-gray-100">
                        {language === "ENG"
                          ? "Certificate of Excellent Technology Company (SCI Evaluation Information)"
                          : "우수기술기업 인증서 (SCI평가정보)"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex flex-col space-y-8 sm:space-y-0 sm:flex-row sm:space-x-8 mt-16 justify-center items-center">
                  <img
                    src={Certificate1Img}
                    alt="Certificate 1"
                    className="w-2/3 sm:w-1/5"
                    data-aos="fade-up"
                    data-aos-duration="750"
                  />
                  <img
                    src={Certificate2Img}
                    alt="Certificate 2"
                    className="w-2/3 sm:w-1/5"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  />
                  <img
                    src={Certificate3Img}
                    alt="Certificate 3"
                    className="w-2/3 sm:w-1/5"
                    data-aos="fade-up"
                    data-aos-duration="750"
                  />
                  <img
                    src={Certificate4Img}
                    alt="Certificate 4"
                    className="w-2/3 sm:w-1/5"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  />
                </div>
              </div>
              <div className="bg-[#1a1a1a] flex flex-col items-center py-16 sm:py-32 px-4">
                <h1
                  className="font-bold text-center text-2xl sm:text-3xl lg:text-6xl text-[#fefefe]"
                  data-aos="fade-down"
                  data-aos-duration="750"
                >
                  {language === "ENG"
                    ? "Intellectual Property Rights"
                    : "지적 재산권"}
                </h1>
                <div className="my-16 w-full">
                  <div
                    className="block sm:hidden mx-4"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    <ul className="list-disc px-8 py-4 border-l-2 border-b-2 border-white space-y-4 text-white bg-[#2a2a2a]">
                      <li>
                        {language === "ENG"
                          ? "Using artificial intelligence to enable mobile electronic device users. Notification method to prevent walking accidents and Mobile electronic devices with these methods"
                          : "인공지능을 이용하여 휴대 전자기기 사용자의 보행 사고를 방지하기 위한 알림방법 및 이러한 방법이 적용된 휴대 전자기기"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "patented-2021-0089632 (2021.07.08)"
                          : "특허-2021-0089632 (2021.07.08)"}
                      </li>
                      <li>
                        {language === "ENG" ? "Patent application" : "특허출원"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "A neural network that functions as an object recognition function of artificial intelligence is implanted in a mobile phone, If the user recognizes the danger by using the rear camera when moving while looking at the mobile phone, Technology that prevents danger by sounding an alarm to the user (DaNimbut App)"
                          : "인공지능의 객제 인식기능을 하는 신경망을 휴대폰에 이식하고, 사용자가 휴대폰을 보면서 이동시 후방카메라를 이용하여 위험을 인식하면 사용자에게 알람을 울려 위험을 방지 하는 기술(DaNimbut(다님벗) App)"}
                      </li>
                    </ul>
                    <ul className="list-disc px-8 py-4 border-r-2 border-b-2 border-white space-y-4 text-white">
                      <li>
                        {language === "ENG"
                          ? "Accident prevention model using deep learning object detection by using mobile electronic device users to prevent walking accidents. How to prevent it"
                          : "딥러닝의 객체 검출을 이용한 사고방지 모델을 이용하여 휴대 전자기기 사용자의 보행 사고를 방지하기 위한 방법"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "patented-2022-0028305 (2022.03.04)"
                          : "특허-2022-0028305 (2022.03.04)"}
                      </li>
                      <li>
                        {language === "ENG" ? "Patent application" : "특허출원"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "A patent that adds UX configuration and procedures for using the DaNimBut App"
                          : "DaNimBut App을 사용하기 위한 UX의 구성 및 절차를 추가한 특허"}
                      </li>
                    </ul>
                    <ul className="list-disc px-8 py-4 border-l-2 border-b-2 border-white space-y-4 text-white bg-[#2a2a2a]">
                      <li>
                        {language === "ENG"
                          ? "Mobile electronic devices using artificial intelligence and user's pedestrian alert app"
                          : "인공지능을 이용한 휴대 전자기기 사용자의 보행주의 알림 앱"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "patented-2022-0036456 (2022.03.24)"
                          : "특허-2022-0036456 (2022.03.24)"}
                      </li>
                      <li>
                        {language === "ENG" ? "Patent application" : "특허출원"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "Pedestrian caution notification app for mobile electronic device users using artificial intelligence"
                          : "인공지능을 이용한 휴대 전자기기 사용자의 보행주의 알림 앱"}
                      </li>
                    </ul>
                    <ul className="list-disc px-8 py-4 border-r-2 border-b-2 border-white space-y-4 text-white">
                      <li>
                        {language === "ENG"
                          ? "Time of Flight (TOF) and ensemble neural networks People Counting"
                          : "TOF(Time of Flight)와 앙상블 신경망을 이용한 피플 카운팅"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "patented-2022-0075230 (2022.06.21)"
                          : "특허-2022-0075230 (2022.06.21)"}
                      </li>
                      <li>
                        {language === "ENG" ? "Patent application" : "특허출원"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "Using an ensemble neural network to image input from the TOF camera, Improves the accuracy of people counting"
                          : "TOF 카메라에서 입력되는 영상을 앙상블 신경망을 이용하여 피플 카운팅의 정확도를 높임"}
                      </li>
                    </ul>
                    <ul className="list-disc px-8 py-4 border-l-2 border-b-2 border-white space-y-4 text-white bg-[#2a2a2a]">
                      <li>
                        {language === "ENG"
                          ? "Fire insurance linked to AI fire control system Service Subscription Platform"
                          : "AI 화재관제시스템 연계 화재보험 서비스 가입 플랫폼"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "patented-10-2022-0142120 (2022.08.19)"
                          : "특허-10-2022-0142120 (2022.08.19)"}
                      </li>
                      <li>
                        {language === "ENG" ? "Patent application" : "특허출원"}
                      </li>
                      <li>
                        The present invention applies to a customer who has or
                        intends to purchase fire insurance. In the case of the
                        introduction of a fire control system, a discount is
                        applied to the paid insurance premium. About a fire
                        insurance service subscription platform that provides
                        special insurance products
                      </li>
                    </ul>
                    <ul className="list-disc px-8 py-4 border-r-2 border-b-2 border-white space-y-4 text-white">
                      <li>
                        {language === "ENG"
                          ? "User walking accident prevention application How to save power on your portable electronic devices"
                          : "사용자 보행 사고 방지 어플리케이션을 탑재한 휴대 전자기기의 전력 절감 방법"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "patented-10-2022-0105167 (2022.08.23)"
                          : "특허-10-2022-0105167 (2022.08.23)"}
                      </li>
                      <li>
                        {language === "ENG" ? "Patent application" : "특허출원"}
                      </li>
                      <li>
                        {language === "ENG"
                          ? "A portable electronic device equipped with a pedestrian caution notification application. How to reduce power consumption"
                          : "보행주의 알림 어플리케이션을 탑재한 휴대 전자기기의 소비 전력을 절감하기 위한 방법에 관한 것"}
                      </li>
                    </ul>
                    <ul className="list-disc px-8 py-4 border-l-2 border-b-2 border-white space-y-4 text-white bg-[#2a2a2a]">
                      <li>
                        {language === "ENG"
                          ? "Fire detection method using deep learning"
                          : "딥러닝을 이용한 화재감지방법"}
                      </li>
                      <li>10-2022-0142120 (2022.10.31)</li>
                      <li>{language === "ENG" ? "Registration" : "등록"}</li>
                      <li>
                        {language === "ENG"
                          ? "A solution that uses deep learning to detect fires at an early stage"
                          : "딥러닝 이용하여 화재를 초기에 감지하는 솔루션"}
                      </li>
                    </ul>
                    <ul className="list-disc px-8 py-4 border-r-2 border-b-2 border-white space-y-4 text-white">
                      <li>
                        {language === "ENG"
                          ? "Prevention of smombie accidents using deep learning-based object detection"
                          : "딥러닝 기반 객체 감지를 통한 스몸비 사고 예방"}
                      </li>
                      <li>S2405-9595(22) 00098-4</li>
                      <li>{language === "ENG" ? "Paper" : "논문"}</li>
                      <li>
                        <a
                          target="_blank"
                          href="https://doi.org/10.1016/j.icte.2022.07.001"
                          className="hover:underline text-xs sm:text-base"
                        >
                          https://doi.org/10.1016/j.icte.2022.07.001
                        </a>
                      </li>
                    </ul>
                  </div>
                  <table
                    className="table-auto sm:mx-8 hidden sm:block"
                    data-aos="fade-down"
                    data-aos-duration="750"
                  >
                    <tbody>
                      <tr className="align-middle bg-[#2a2a2a] text-[#fefefe] text-xs lg:text-lg border-b">
                        <td className="border-r p-4 w-1/4">
                          {language === "ENG"
                            ? "Using artificial intelligence to enable mobile electronic device users. Notification method to prevent walking accidents and Mobile electronic devices with these methods"
                            : "인공지능을 이용하여 휴대 전자기기 사용자의 보행 사고를 방지하기 위한 알림방법 및 이러한 방법이 적용된 휴대 전자기기"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "patented-2021-0089632 (2021.07.08)"
                            : "특허-2021-0089632 (2021.07.08)"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "Patent application"
                            : "특허출원"}
                        </td>
                        <td className="p-4 w-1/4">
                          {language === "ENG"
                            ? "A neural network that functions as an object recognition function of artificial intelligence is implanted in a mobile phone, If the user recognizes the danger by using the rear camera when moving while looking at the mobile phone, Technology that prevents danger by sounding an alarm to the user (DaNimbut App)"
                            : "인공지능의 객제 인식기능을 하는 신경망을 휴대폰에 이식하고, 사용자가 휴대폰을 보면서 이동시 후방카메라를 이용하여 위험을 인식하면 사용자에게 알람을 울려 위험을 방지 하는 기술(DaNimbut(다님벗) App)"}
                        </td>
                      </tr>
                      <tr className="align-middle text-[#fefefe] text-xs lg:text-lg border-b">
                        <td className="border-r p-4 w-1/4">
                          {language === "ENG"
                            ? "Accident prevention model using deep learning object detection by using mobile electronic device users to prevent walking accidents. How to prevent it"
                            : "딥러닝의 객체 검출을 이용한 사고방지 모델을 이용하여 휴대 전자기기 사용자의 보행 사고를 방지하기 위한 방법"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "patented-2022-0028305 (2022.03.04)"
                            : "특허-2022-0028305 (2022.03.04)"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "Patent application"
                            : "특허출원"}
                        </td>
                        <td className="p-4 w-1/4">
                          {language === "ENG"
                            ? "A patent that adds UX configuration and procedures for using the DaNimBut App"
                            : "DaNimBut App을 사용하기 위한 UX의 구성 및 절차를 추가한 특허"}
                        </td>
                      </tr>
                      <tr className="align-middle bg-[#2a2a2a] text-[#fefefe] text-xs lg:text-lg border-b">
                        <td className="border-r p-4 w-1/4">
                          {language === "ENG"
                            ? "Mobile electronic devices using artificial intelligence and user's pedestrian alert app"
                            : "인공지능을 이용한 휴대 전자기기 사용자의 보행주의 알림 앱"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "patented-2022-0036456 (2022.03.24)"
                            : "특허-2022-0036456 (2022.03.24)"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "Patent application"
                            : "특허출원"}
                        </td>
                        <td className="p-4 w-1/4">
                          {language === "ENG"
                            ? "Pedestrian caution notification app for mobile electronic device users using artificial intelligence"
                            : "인공지능을 이용한 휴대 전자기기 사용자의 보행주의 알림 앱"}
                        </td>
                      </tr>
                      <tr className="align-middle text-[#fefefe] text-xs lg:text-lg border-b">
                        <td className="border-r p-4 w-1/4">
                          {language === "ENG"
                            ? "Time of Flight (TOF) and ensemble neural networks People Counting"
                            : "TOF(Time of Flight)와 앙상블 신경망을 이용한 피플 카운팅"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "patented-2022-0075230 (2022.06.21)"
                            : "특허-2022-0075230 (2022.06.21)"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "Patent application"
                            : "특허출원"}
                        </td>
                        <td className="p-4 w-1/4">
                          {language === "ENG"
                            ? "Using an ensemble neural network to image input from the TOF camera, Improves the accuracy of people counting"
                            : "TOF 카메라에서 입력되는 영상을 앙상블 신경망을 이용하여 피플 카운팅의 정확도를 높임"}
                        </td>
                      </tr>
                      <tr className="align-middle bg-[#2a2a2a] text-[#fefefe] text-xs lg:text-lg border-b">
                        <td className="border-r p-4 w-1/4">
                          {language === "ENG"
                            ? "Fire insurance linked to AI fire control system Service Subscription Platform"
                            : "AI 화재관제시스템 연계 화재보험 서비스 가입 플랫폼"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "patented-10-2022-0142120 (2022.08.19)"
                            : "특허-10-2022-0142120 (2022.08.19)"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "Patent application"
                            : "특허출원"}
                        </td>
                        <td className="p-4 w-1/4">
                          {language === "ENG"
                            ? "The present invention applies to a customer who has or intends to purchase fire insurance. In the case of the introduction of a fire control system, a discount is applied to the paid insurance premium. About a fire insurance service subscription platform that provides special insurance products"
                            : "본 발명은, 화재 보험에 가입하였거나 가입하고자 하는 고객이 화재관제 시스템을 도입한 경우, 납입 보험료에 할인이 적용된 특약 보험 상품을 제공하는 화재보험 서비스 가입 플랫폼에 관한 것"}
                        </td>
                      </tr>
                      <tr className="align-middle text-[#fefefe] text-xs lg:text-lg border-b">
                        <td className="border-r p-4 w-1/4">
                          {language === "ENG"
                            ? "User walking accident prevention application How to save power on your portable electronic devices"
                            : "사용자 보행 사고 방지 어플리케이션을 탑재한 휴대 전자기기의 전력 절감 방법"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "patented-10-2022-0105167 (2022.08.23)"
                            : "특허-10-2022-0105167 (2022.08.23)"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG"
                            ? "Patent application"
                            : "특허출원"}
                        </td>
                        <td className="p-4 w-1/4">
                          {language === "ENG"
                            ? "A portable electronic device equipped with a pedestrian caution notification application. How to reduce power consumption"
                            : "보행주의 알림 어플리케이션을 탑재한 휴대 전자기기의 소비 전력을 절감하기 위한 방법에 관한 것"}
                        </td>
                      </tr>
                      <tr className="align-middle bg-[#2a2a2a] text-[#fefefe] text-xs lg:text-lg border-b">
                        <td className="border-r p-4 w-1/4">
                          {language === "ENG"
                            ? "Fire detection method using deep learning"
                            : "딥러닝을 이용한 화재감지방법"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          10-2022-0142120 (2022.10.31)
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG" ? "Registration" : "등록"}
                        </td>
                        <td className="p-4 w-1/4">
                          {language === "ENG"
                            ? "A solution that uses deep learning to detect fires at an early stage"
                            : "딥러닝 이용하여 화재를 초기에 감지하는 솔루션"}
                        </td>
                      </tr>
                      <tr className="align-middle text-[#fefefe] text-xs lg:text-lg">
                        <td className="border-r p-4 w-1/4">
                          {language === "ENG"
                            ? "Prevention of smombie accidents using deep learning-based object detection"
                            : "딥러닝 기반 객체 감지를 통한 스몸비 사고 예방"}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          S2405-9595(22) 00098-4{" "}
                        </td>
                        <td className="border-r p-4 text-center w-1/4">
                          {language === "ENG" ? "Paper" : "논문"}
                        </td>
                        <td className="p-4 w-1/4">
                          <a
                            target="_blank"
                            href="https://doi.org/10.1016/j.icte.2022.07.001"
                            className="hover:underline"
                          >
                            https://doi.org/10.1016/j.icte.2022.07.001
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
