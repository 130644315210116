import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundProductIntroduction from "../../Assets/header background product introduction.png";
import Footer from "../../Components/Footer";
import AiVisionImg from "../../Assets/ai vision features.png";
import AiVisionKORImg from "../../Assets/ai vision features KOR.png";
import OtherProduct1 from "../../Assets/AI Box Other.png";
import OtherProduct2 from "../../Assets/AI Face Other.png";
import OtherProduct3 from "../../Assets/AI Fire Other.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function AiVisionFeatures() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={2} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Product Introduction" : "제품 소개"
        }`}
        subtitle={`${
          language === "ENG" ? "AI Vision Features" : "AI Vision 기능"
        }`}
        background={BackgroundProductIntroduction}
        colorBorder={"#32a7df"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start w-fit">
        <LeftMenu currentChoose={0} currentBold={"AI Vision Features"} />
        <div className="flex flex-col px-0 sm:px-8">
          <div className="flex flex-col">
            <h2 className="mx-8 font-bold text-3xl mb-2 md:mb-8">
              {language === "ENG"
                ? "Introduction AERY AI Vision Features"
                : "AERY AI Vision Features를 소개합니다"}
            </h2>
            <div className="w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden">
              <img
                src={language === "ENG" ? AiVisionImg : AiVisionKORImg}
                alt="AI Vision Features"
                onContextMenu={(e) => e.preventDefault()}
                className="min-w-[700px] sm:min-w-0 sm:w-full mt-12 px-8"
                data-aos="flip-up"
                data-aos-duration="750"
              />
            </div>
          </div>
          <OtherContent
            title={`${language === "ENG" ? "Other Products" : "다른 제품들"}`}
            titlecard1={`${
              language === "ENG"
                ? "AI BOX System Diagram"
                : "AI BOX 시스템 구성도"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "AI Face Recognition Solution"
                : "AI 안면인식 솔루션"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "AI Fire Detection Solution"
                : "AI 화재감지 솔루션"
            }`}
            image1={OtherProduct1}
            image2={OtherProduct2}
            image3={OtherProduct3}
            href1={"/ai-box"}
            href2={"/ai-face-recognition"}
            href3={"/ai-fire-detection"}
          />
        </div>
      </div>
      <Footer />
      <ButtonUp />
    </>
  );
}
