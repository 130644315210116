import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundBusinessStrategy from "../../Assets/header background business strategy.png";
import Footer from "../../Components/Footer";
import BPPublicInstitutionImg from "../../Assets/BP Public Institution.png";
import OtherBusiness1 from "../../Assets/Other Business Strategy 4.png";
import OtherBusiness2 from "../../Assets/Other Business Strategy 1.png";
import OtherBusiness3 from "../../Assets/Other Business Strategy 2.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function BPPublicInstitution() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={4} />
      <HeaderContent
        title={`${
          language === "ENG" ? "Business Promotion Strategy" : "사업추진전략"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Public Institution and Local Government Marketing Strategy"
            : "공공기관 및 지자체 마케팅 전략"
        }`}
        background={BackgroundBusinessStrategy}
        colorBorder={"#f54545"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start w-fit">
        <LeftMenu
          currentChoose={2}
          currentBold={
            "Public Institution and Local Government Marketing Strategy"
          }
        />
        <div className="flex flex-col px-0 sm:px-8">
          <div className="flex flex-col">
            <h2 className="mx-8 font-bold text-3xl mb-8">
              {language === "ENG"
                ? "AERY Technology for Public Services"
                : "공공기관 및 지자체 마케팅 전략"}
            </h2>
            <div
              className="mx-8 flex flex-row items-center space-x-2"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <span className="text-xl">
                {language === "ENG"
                  ? "Selecting local autonomous organizations or public institutions to set up free AI solution services and conducting a pilot operation to demonstrate the areas where the limitations of existing CCTV products are addressed. After proving these points, the goal is to expand the service nationwide."
                  : "지방 자치 단체 또는 공공기관을 선정하여 무료 AI Solution 서비스를 Set up하고 Pilot운영을 통해 기존의 CCTV제품의 한계점이 보완되는 부분을 증명한 후 전국적으로 확산"}
              </span>
            </div>
            <div className="w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden">
              <img
                src={BPPublicInstitutionImg}
                alt="Public Institution"
                onContextMenu={(e) => e.preventDefault()}
                className="min-w-[700px] sm:min-w-0 sm:w-full mt-12 px-8"
                data-aos="flip-down"
                data-aos-duration="750"
              />
            </div>
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Business Strategy" : "기타 사업 전략"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Solution Advancement Strategy"
                : "Solution 고도화 전략"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "AI BOX Solution Development Strategy"
                : "AI BOX Solution 개발전략"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Franchise Brand Marketing Strategy"
                : "프랜차이즈 브랜트 마케팅 전략"
            }`}
            image1={OtherBusiness1}
            image2={OtherBusiness2}
            image3={OtherBusiness3}
            href1={"/bp-solution-advance"}
            href2={"/bp-ai-box"}
            href3={"/bp-franchise"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
