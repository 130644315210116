import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundSolutionApplication from "../../Assets/header background solution application.png";
import Footer from "../../Components/Footer";
import SaApartment1 from "../../Assets/sa apartment 1.svg";
import SaApartment1KOR from "../../Assets/sa apartment 1 KOR.svg";
import SaApartment2 from "../../Assets/sa apartment 2.svg";
import SaApartment3 from "../../Assets/sa apartment 3.svg";
import SaApartment4 from "../../Assets/sa apartment 4.svg";
import OtherSolutions1 from "../../Assets/App Solution 2.png";
import OtherSolutions2 from "../../Assets/App Solution 3.png";
import OtherSolutions3 from "../../Assets/App Solution 4.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function SaApartmentSecurity() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={3} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Solution Applications" : "솔루션 활용분야"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Apartment Security Management"
            : "아파트 보안/화재/안전관리"
        }`}
        background={BackgroundSolutionApplication}
        colorBorder={"#f48e35"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start w-fit">
        <LeftMenu
          currentChoose={1}
          currentBold={"Apartment Security Management"}
        />
        <div className="flex flex-col px-0 sm:px-8">
          <div className="flex flex-col">
            <h2 className="mx-8 font-bold text-3xl lg:text-4xl mb-8">
              {language === "ENG"
                ? "Manage Automated and Smart Building Systems"
                : "자동화되고 스마트한 빌딩 시스템 관리"}
            </h2>
            <div className="w-screen sm:w-auto overflow-x-auto sm:overflow-x-hidden">
              <img
                src={language === "ENG" ? SaApartment1 : SaApartment1KOR}
                alt="Sa Apartment 1"
                onContextMenu={(e) => e.preventDefault()}
                className="min-w-[700px] sm:min-w-0 sm:w-full mt-12 px-8 self-center"
                data-aos="fade-down"
                data-aos-duration="750"
              />
            </div>
            <div
              className="mx-auto px-8 mt-10 mb-12 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 pt-10 sm:mt-12 sm:pt-12 lg:mx-0 lg:max-w-none lg:grid-cols-3"
              data-aos="zoom-in-down"
              data-aos-duration="750"
            >
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Apartment" : "아파트"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG" ? "Video Intercom" : "비디오 인터폰"}
                  </li>
                  <li>{language === "ENG" ? "Access Control" : "출입 통제"}</li>
                  <li>
                    {language === "ENG"
                      ? "Abnormal Behavior Monitoring:"
                      : "이상행동 감시"}
                  </li>
                  <li>
                    {language === "ENG" ? "Fire Monitoring" : "화재 감시"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Management Room" : "관리실"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>{language === "ENG" ? "Video Wall" : "비디오 월"}</li>
                  <li>
                    {language === "ENG" ? "Access Control" : "액세스 제어"}
                  </li>
                  <li>
                    {language === "ENG" ? "Management Platform" : "관리 플랫폼"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Garbage Dump" : "울타리"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG"
                      ? "Surveillance of unauthorized dumping of garbage"
                      : "침입 경보"}
                  </li>
                  <li>
                    {language === "ENG" ? "Fire Monitoring" : "이상행동 감시"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Fence" : "쓰레기장"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG"
                      ? "Instrusion Alarms"
                      : "쓰레기 무단 투기 감시"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Abnormal Behavior Monitoring"
                      : "화재 감시"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG" ? "Playground" : "놀이터"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG"
                      ? "Stranger Loitering Spying"
                      : "낯선 배회자 감시"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Emergency monitoring"
                      : "응급상황 감시"}
                  </li>
                  <li>
                    {language === "ENG" ? "Fire Monitoring" : "화재 감시"}
                  </li>
                  <li>
                    {language === "ENG"
                      ? "Check the children on the intercom"
                      : "인터폰으로 아이들 확인"}
                  </li>
                </ul>
              </div>
              <div className="border border-[#6e6b6c] rounded-3xl">
                <h3 className="w-full font-bold object-cover bg-[#222222] text-white text-center p-2 text-xl rounded-t-3xl">
                  {language === "ENG"
                    ? "Entrances and Exit Gate"
                    : "출입구 및 출구"}
                </h3>
                <ul className="list-disc p-4 ml-4">
                  <li>
                    {language === "ENG"
                      ? "License Plate Recognition"
                      : "차량번호판 인식"}
                  </li>
                  <li>{language === "ENG" ? "Access Control" : "출입 통제"}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-[#1a1a1a] py-20">
            <div className="px-8 flex flex-col space-y-8">
              <div
                className="flex flex-col lg:flex-row lg:space-x-4 justify-center items-center text-[#fefefe] font-medium text-2xl space-y-4"
                data-aos="fade-down"
                data-aos-duration="750"
              >
                <img
                  src={SaApartment2}
                  alt="Sa Apartment 2"
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-full lg:w-1/2"
                />
                <div className="flex flex-col border-white border-2 p-4 rounded-3xl">
                  <span className="bg-white text-black text-center w-full p-2 rounded-2xl sm:rounded-3xl font-bold">
                    {language === "ENG"
                      ? "Apartment Electric Car Charger Fire"
                      : "아파트 전기자동차 충전기 화재"}
                  </span>
                  <p className="mt-4 text-justify font-normal text-xl">
                    {language === "ENG"
                      ? "“When a fire occurs during the charging of an electric vehicle, if the initial response is not handled properly, it can lead to significant damage and casualties.”"
                      : "전기차 충전중 화재 발생시 초기 대응을 못해 모두 불에 타 피해가 크게 발행한다"}
                  </p>
                </div>
              </div>
              <div
                className="flex flex-col-reverse lg:flex-row lg:space-x-4 justify-center items-center text-[#fefefe] !mt-20"
                data-aos="fade-up"
                data-aos-duration="750"
              >
                <div className="flex flex-col space-y-4">
                  <div className="border-white border-2 p-4 rounded-3xl text-2xl">
                    <p className="text-xl font-normal text-justify">
                      {language === "ENG"
                        ? "AI analysis of images input through general CCTV The ability to notify the control center of the danger at the beginning of the fire"
                        : "일반 CCTV를 통해 입력되는 영상을 AI 분석 화재 초기에 관제 센터에 위험을 알리는 기능"}
                    </p>
                  </div>
                  <div className="border-white border-2 p-4 rounded-3xl text-2xl">
                    <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 space-x-4">
                      <img
                        src={SaApartment4}
                        alt="Sa Apartment 4"
                        onContextMenu={(e) => e.preventDefault()}
                        className="mx-2 sm:w-1/3 lg:w-1/4"
                      />
                      <div className="flex flex-col justify-center items-center">
                        <span className="text-lg font-regular text-[#ffef15]">
                          {language === "ENG"
                            ? "Smoke and flame detection around electric vehicle charging"
                            : "전기차 충전주변 연기·불꽃 감지"}
                        </span>
                        <span className="text-base font-regular mb-8">
                          {language === "ENG"
                            ? "Minimum detection time: AI method 0.3 second flame detection"
                            : "최소 감지 시간 : AI방식 0.3초 불꽃 감지"}
                        </span>
                        <span className="text-lg font-regular text-[#e5442c]">
                          {language === "ENG"
                            ? "Detection of smoke and flames around the charger"
                            : "충전기 주변 연기·불꽃 감지"}
                        </span>
                        <span className="text-base font-regular">
                          {language === "ENG"
                            ? "Minimum size for flame detection: monitor screen 20pix x 20pix (Actual flame 1cm x 1cm)"
                            : "불꽃 감지 최소 크기 : 모니터화면 20pix x 20pix (실제 불꽃 1cm x 1cm)"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={SaApartment3}
                  alt="Sa Apartment 3"
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-full lg:w-1/2"
                />
              </div>
            </div>
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Solution Area" : "기타 솔루션 영역"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Large Mart Shopping Mall Security"
                : "대형마트/쇼핑몰 보안/화재/안전관리"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Public Security Social Safety Net"
                : "공공 방범/치안/사회안전망"
            }`}
            titlecard3={`${
              language === "ENG" ? "Bank Security" : "은행 방범/보이스피싱/안전"
            }`}
            image1={OtherSolutions1}
            image2={OtherSolutions2}
            image3={OtherSolutions3}
            href1={"/sa-large-mart"}
            href2={"/sa-public-security"}
            href3={"/sa-bank-security"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
