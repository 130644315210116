import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import AboutUs from "./Pages/AboutUs";
import LearnMore from "./Pages/LearnMore";
import BPAiBox from "./Pages/Business Promotion/BPAiBox";
import BPFranchiseBrand from "./Pages/Business Promotion/BPFranchiseBrand";
import BPSolutionAdvance from "./Pages/Business Promotion/BPSolutionAdvance";
import BPPublicInstitution from "./Pages/Business Promotion/BPPublicInstitution";
import AiVisionFeatures from "./Pages/Product Introduction/AiVisionFeatures";
import AiBoxSystemDiagram from "./Pages/Product Introduction/AiBoxSystemDiagram";
import AiFaceRecog from "./Pages/Product Introduction/AiFaceRecog";
import AiFireDetect from "./Pages/Product Introduction/AiFireDetect";
import AiAbnormalBehav from "./Pages/Product Introduction/AiAbnormalBehav";
import AiObjectTracking from "./Pages/Product Introduction/AiObjectTracking";
import SaApartmentSecurity from "./Pages/Solution Application/SaApartmentSecurity";
import SaLargeMart from "./Pages/Solution Application/SaLargeMart";
import SaPublicSecurity from "./Pages/Solution Application/SaPublicSecurity";
import SaBankSecurity from "./Pages/Solution Application/SaBankSecurity";
import SaShoppingMalls from "./Pages/Solution Application/SaShoppingMalls";
import SaCustomizedRecommend from "./Pages/Solution Application/SaCustomizedRecommend";
import SaManufacturing from "./Pages/Solution Application/SaManufacturing";
import SaPreventionDisasters from "./Pages/Solution Application/SaPreventionDisasters";
import AOS from "aos";
import "aos/dist/aos.css";

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/learn-more" element={<LearnMore />} />
        <Route path="/ai-vision" element={<AiVisionFeatures />} />
        <Route path="/ai-box" element={<AiBoxSystemDiagram />} />
        <Route path="/ai-face-recognition" element={<AiFaceRecog />} />
        <Route path="/ai-fire-detection" element={<AiFireDetect />} />
        <Route path="/ai-abnormal-behavior" element={<AiAbnormalBehav />} />
        <Route path="/ai-object-tracking" element={<AiObjectTracking />} />
        <Route
          path="/sa-apartment-security"
          element={<SaApartmentSecurity />}
        />
        <Route path="/sa-large-mart" element={<SaLargeMart />} />
        <Route path="/sa-public-security" element={<SaPublicSecurity />} />
        <Route path="/sa-bank-security" element={<SaBankSecurity />} />
        <Route path="/sa-shopping-malls" element={<SaShoppingMalls />} />
        <Route path="/sa-customized" element={<SaCustomizedRecommend />} />
        <Route path="/sa-manufacturing" element={<SaManufacturing />} />
        <Route
          path="/sa-prevention-disasters"
          element={<SaPreventionDisasters />}
        />
        <Route path="/bp-ai-box" element={<BPAiBox />} />
        <Route path="/bp-franchise" element={<BPFranchiseBrand />} />
        <Route
          path="/bp-public-institution"
          element={<BPPublicInstitution />}
        />
        <Route path="/bp-solution-advance" element={<BPSolutionAdvance />} />
      </Routes>
    </BrowserRouter>
  );
}
