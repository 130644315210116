import React from "react";

export default function HeaderContent({
  title,
  subtitle,
  background,
  colorBorder,
}) {
  return (
    <>
      <div className="background-container">
        <img
          src={background}
          alt="Background Header"
          onContextMenu={(e) => e.preventDefault()}
          className="object-cover opacity-20 h-[388px] w-full md:h-[394px] lg:h-[404px] xl:h-[488px]"
        />
      </div>
      <div
        id="stickytypeheader"
        className="my-8 ml-12 mr-8 md:ml-60 lg:ml-80 lg:mr-16 md:mr-16 md:my-16 2xl:ml-96 2xl:my-24"
      >
        <hr className={`border-t-4 border-[${colorBorder}] w-36 mb-2`} />
        <span className="text-white font-bold text-xl md:text-2xl xl:text-3xl">
          {title}
        </span>
        <h1 className="text-white font-bold text-2xl md:text-3xl xl:text-5xl mt-2 pb-12">
          {subtitle}
        </h1>
      </div>
    </>
  );
}
