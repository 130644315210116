import React from "react";
import Navbars from "../Components/Navbars";
import LearnMoreEnterpriseImg from "../Assets/learn more section header.svg";
import LearnMoreProductIntroductionImg from "../Assets/Learn More Product Introduction.svg";
import LearnMoreApplicationsImg from "../Assets/Learn More Applications.svg";
import LearnMoreBusinessImg from "../Assets/Learn More Business Promotion.svg";
import Footer from "../Components/Footer";
import ButtonUp from "../Components/ButtonUp";
import { useLanguage } from "../Context/LanguageContext";

export default function LearnMore() {
  const { language } = useLanguage();

  return (
    <div className="bg-white">
      <Navbars textblack />
      <div className="mx-0 sm:mx-8 lg:mx-32 sm:my-12">
        <div className="bg-black relative mt-8 sm:mt-0">
          <img
            src={LearnMoreEnterpriseImg}
            alt="Learn More"
            className="opacity-25 h-full sm:w-full sm:h-auto"
          />
          <div className="absolute top-1/3 left-4 lg:top-16 lg:left-16">
            <hr className="border-t-4 border-[#32a7df] w-20 sm:w-40 md:mb-4" />
            <h1 className="text-white font-bold text-sm mt-1 sm:mt-0 sm:text-xl md:text-2xl lg:text-3xl">
              {language === "ENG"
                ? "AI Solutions for Utility Enterprises"
                : "유틸리티 기업을 위한 AI 솔루션"}{" "}
            </h1>
          </div>
        </div>
        <div className="mx-8 sm:mx-0 my-12">
          <h1 className="font-bold text-5xl mb-8">
            {language === "ENG"
              ? "AERY AI Applications"
              : "AERY AI 애플리케이션"}
          </h1>
          <p className="text-justify">
            {language === "ENG"
              ? "Our AI-driven products represent leading-edge innovation, designed to transform the way businesses operate in an increasingly data-driven world. Every product in our portfolio is carefully designed to harness the power of artificial intelligence, simplify processes and increase efficiency. With AI at our core, we offer a new level of intelligent automation and predictive capabilities, enabling businesses to stay ahead. These products have been rigorously tested and proven to be useful in the real world, making them a reliable choice for organizations seeking rapid value and sustainable growth. Experience the future of technology with our AI-enabled products, where every solution is a step towards a smarter, data-powered future."
              : "AI를 활용한 저희 제품은 선두적인 혁신을 대표하며, 기업이 점차적으로 데이터 중심의 세계에서 운영 방식을 변화시키는 데 기여하도록 설계되었습니다. 저희 포트폴리오의 각 제품은 인공 지능의 힘을 효과적으로 활용하기 위해 신중하게 설계되었으며, 프로세스를 간소화하고 효율성을 높이는 데 목적을 두고 있습니다. AI가 핵심에 내재되어 있어 우리는 지능적인 자동화 및 예측 능력의 새로운 수준을 제공하여 기업이 앞서 나갈 수 있도록 지원합니다. 이러한 제품들은 엄격하게 테스트되었으며 실제 세계에서 유용함이 입증되어, 신속한 가치 창출과 지속 가능한 성장을 추구하는 기관들에게 신뢰할 수 있는 선택지로 입증되었습니다. AI가 가능케 하는 미래의 기술을 체험하세요. 여기서 모든 해결책은 더 스마트하고 데이터 기반의 미래로 나아가는 한 걸음입니다."}
          </p>
        </div>
      </div>
      <div className="bg-[#ebebeb] flex flex-col p-8 lg:p-32">
        <div className="mb-16" data-aos="flip-up" data-aos-duration="750">
          <div className="bg-[#1a1a1a] flex flex-col-reverse md:flex-row justify-between items-center">
            <h1 className="text-white font-bold text-lg md:text-base lg:text-2xl m-4 lg:m-8">
              {language === "ENG"
                ? "AERY Product Introduction"
                : "AERY 제품 소개"}
            </h1>
            <img
              src={LearnMoreProductIntroductionImg}
              alt="Learn More Product Introduction"
              className="w-full h-1/2 md:w-1/2 md:h-full object-fill"
            />
          </div>
          <div className="bg-[#fefefe] flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-20 justify-start p-12">
            <div className="flex flex-col w-full md:w-1/2">
              <h2 className="text-[#0a0a0a] font-bold mb-8 text-lg">
                {language === "ENG" ? "Industry Problem" : "산업별 문제"}
              </h2>
              <p className="text-base text-justify">
                {language === "ENG"
                  ? "Also known as machine intelligence, artificial intelligence (AI) is a type of technology that helps create machines that can perform specific tasks that normally require human intelligence, such as visual perception, speech recognition, and decision-making."
                  : "기계 지능이라고도 불리는 인공 지능(AI)은 일반적으로 인간의 지능이 필요한 시각 지각, 음성 인식 및 의사 결정과 같은 특정 작업을 수행할 수 있는 기계를 만드는 기술 유형입니다."}
              </p>
              <br />
              <p className="text-base text-justify">
                {language === "ENG"
                  ? "AI helps with data interpretation and specialized knowledge in specialized fields like natural language processing, learning, planning, and executing. Artificial intelligence is a diverse field composed of many smaller fields and disciplines."
                  : "AI는 자연어 처리, 학습, 계획 및 실행과 같은 특수 분야에서 데이터 해석 및 전문 지식에 도움을 줍니다. 인공 지능은 많은 작은 분야와 학문으로 구성된 다양한 분야입니다."}
              </p>
            </div>
            <div className="flex flex-col w-full md:w-1/2">
              <h2 className="text-[#0a0a0a] font-bold mb-4 md:mb-8 text-lg">
                {language === "ENG" ? "AERY Products" : "AERY 제품소개"}
              </h2>
              <ul className="list-disc underline space-y-2">
                <li>
                  <a href="/ai-vision" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "AI Vision Features"
                      : "AI Vision 기능"}
                  </a>
                </li>
                <li>
                  <a href="/ai-box" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "AI BOX System Diagram"
                      : "AI BOX 시스템 구성도"}
                  </a>
                </li>
                <li>
                  <a
                    href="/ai-face-recognition"
                    className="hover:text-slate-400"
                  >
                    {language === "ENG"
                      ? "AI Face Recognition Solution"
                      : "AI 안면인식 솔루션"}
                  </a>
                </li>
                <li>
                  <a href="/ai-fire-detection" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "AI Fire Detection Solution"
                      : "AI 화재감지 솔루션"}
                  </a>
                </li>
                <li>
                  <a
                    href="/ai-abnormal-behavior"
                    className="hover:text-slate-400"
                  >
                    {language === "ENG"
                      ? "AI Abnormal Behavior Detection Solution"
                      : "AI 이상행동감지 솔루션"}
                  </a>
                </li>
                <li>
                  <a
                    href="/ai-object-tracking"
                    className="hover:text-slate-400"
                  >
                    {language === "ENG"
                      ? "AI Object Tracking Solution"
                      : "AI 객체추적 솔루션"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-16" data-aos="flip-right" data-aos-duration="750">
          <div className="bg-[#1a1a1a] flex flex-col-reverse md:flex-row justify-between items-center">
            <h1 className="text-white font-bold text-lg md:text-base lg:text-2xl m-4 lg:m-8">
              {language === "ENG"
                ? "AERY Application Solutions"
                : "AERY 제품 활용분야"}
            </h1>
            <img
              src={LearnMoreApplicationsImg}
              alt="Learn More Application Solutions"
              className="w-full h-1/2 md:w-1/2 md:h-full object-fill"
            />
          </div>
          <div className="bg-[#fefefe] flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-20 justify-start p-12">
            <div className="flex flex-col w-full md:w-1/2">
              <h2 className="text-[#0a0a0a] font-bold mb-8 text-lg">
                {language === "ENG" ? "Industry Problem" : "산업별 문제"}
              </h2>
              <p className="text-base text-justify">
                {language === "ENG"
                  ? "Technological advancements and innovation have helped manufacturing industries to grow exponentially in the past few years. Companies are no longer limited to using old and offline trends. They are now making significant shifts to online activities. Unfortunately, this has also led to increasing incidences of cyber-attacks."
                  : "기술의 발전과 혁신은 지난 몇 년 동안 제조 산업의 급격한 성장을 이끌었습니다. 기업들은 이제 더 이상 옛날이나 오프라인 트렌드에 제한되지 않습니다. 그들은 현재 온라인 활동으로 중요한 전환을 이루고 있습니다. 유감스럽게도, 이는 사이버 공격 사례의 증가로 이어졌습니다."}
              </p>
              <br />
              <p className="text-base text-justify">
                {language === "ENG"
                  ? "Manufacturers should be aware of the different cybersecurity threats the industry faces today. They must also develop effective solutions to address these threats and minimize risks, which includes using AI to its full potential."
                  : "제조업체는 현재 산업이 직면한 다양한 사이버 보안 위협을 인식해야 합니다. 또한 이러한 위협에 대처하고 위험을 최소화하기 위한 효과적인 해결책을 개발해야 합니다. 이는 인공 지능을 최대한 활용하는 것을 포함합니다."}
              </p>
            </div>
            <div className="flex flex-col w-full md:w-1/2">
              <h2 className="text-[#0a0a0a] font-bold mb-4 md:mb-8 text-lg">
                {language === "ENG" ? "AERY Solutions" : "AERY 제품 활용분야"}
              </h2>
              <ul className="list-disc underline space-y-2">
                <li>
                  <a
                    href="/sa-apartment-security"
                    className="hover:text-slate-400"
                  >
                    {language === "ENG"
                      ? "Apartment Security/Fire/Safety Management"
                      : "아파트 보안/화재/안전관리"}
                  </a>
                </li>
                <li>
                  <a href="/sa-large-mart" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "Large Mart/Shopping Mall Security/Fire/Safety Management"
                      : "대형마트/쇼핑몰 보안/화재/안전관리"}
                  </a>
                </li>
                <li>
                  <a
                    href="/sa-public-security"
                    className="hover:text-slate-400"
                  >
                    {language === "ENG"
                      ? "Public Security/Policing/Social Safety Net"
                      : "공공 방범/치안/사회안전망"}
                  </a>
                </li>
                <li>
                  <a href="/sa-bank-security" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "Bank Security/Voice Phising/Safety"
                      : "은행 방범/보이스피싱/안전"}
                  </a>
                </li>
                <li>
                  <a href="/sa-shopping-malls" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "Shopping Malls/Security Facilities Crime Prevention/Security"
                      : "상가/보안시설 방범/치안"}
                  </a>
                </li>
                <li>
                  <a href="/sa-customized" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "Customized Recommendation Service for the Service Industry"
                      : "서비스업 맞춤형 추천 서비스"}
                  </a>
                </li>
                <li>
                  <a href="/sa-manufacturing" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "Manufacturing Quality Control (QC)"
                      : "제조업 품질관리(QC)"}
                  </a>
                </li>
                <li>
                  <a
                    href="/sa-prevention-disasters"
                    className="hover:text-slate-400"
                  >
                    {language === "ENG"
                      ? "Prevention of Early Detection of Natural Disasters/Fire/Safety"
                      : "자연재해/화재/안전 조기감지 예방"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-16" data-aos="flip-left" data-aos-duration="750">
          <div className="bg-[#1a1a1a] flex flex-col-reverse md:flex-row justify-between items-center">
            <h1 className="text-white font-bold text-lg md:text-base lg:text-2xl m-4 lg:m-8">
              {language === "ENG"
                ? "Business Promotion Strategy"
                : "AERY 사업추진전략"}
            </h1>
            <img
              src={LearnMoreBusinessImg}
              alt="Learn More Application Solutions"
              className="w-full h-1/2 md:w-1/2 md:h-full object-fill"
            />
          </div>
          <div className="bg-[#fefefe] flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-20 justify-start p-12">
            <div className="flex flex-col w-full md:w-1/2">
              <h2 className="text-[#0a0a0a] font-bold mb-4 md:mb-8 text-lg">
                {language === "ENG" ? "Industry Problem" : "산업별 문제"}
              </h2>
              <p className="text-base text-justify">
                {language === "ENG"
                  ? "AI will drive the next revolution that is disruptive across most industries. AI applications are found in the following industries: Business Process Automation (BPA), Internet of Things (IoT), Robotics, Autonomous Vehicles, Cyber Security, Intelligent Virtual Assistants (IVA), Cognitive Computing and Big Data."
                  : "인공지능(AI)은 대부분의 산업에 파괴적인 영향을 미칠 다음 혁명을 주도할 것으로 예측됩니다. 인공지능 응용 프로그램은 다음과 같은 산업에서 발견됩니다: 비즈니스 프로세스 자동화(BPA), 사물 인터넷(IoT), 로봇공학, 자율 주행 차량, 사이버 보안, 지능형 가상 비서(IVA), 인지 컴퓨팅 및 빅데이터."}
              </p>
              <br />
              <p className="text-base text-justify">
                {language === "ENG"
                  ? "It is already having a meaningful impact on how businesses are being run now with multiple use cases in the technology stack, including speech recognition, computer vision, machine learning, natural language processing (NLP), text analytics, and social media monitoring."
                  : "이미 기술 스택에서 여러 사용 사례를 통해 비즈니스 운영에 의미 있는 영향을 미치고 있으며, 이는 음성 인식, 컴퓨터 비전, 기계 학습, 자연어 처리(NLP), 텍스트 분석 및 소셜 미디어 모니터링을 포함합니다."}
              </p>
            </div>
            <div className="flex flex-col w-full md:w-1/2">
              <h2 className="text-[#0a0a0a] font-bold mb-8 text-lg">
                {language === "ENG"
                  ? "AERY Business Strategy"
                  : "AERY 사업추진전략"}
              </h2>
              <ul className="list-disc underline space-y-2">
                <li>
                  <a href="/bp-ai-box" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "AI BOX Solution Development Strategy"
                      : "AI BOX Solution 개발전략"}
                  </a>
                </li>
                <li>
                  <a href="/bp-franchise" className="hover:text-slate-400">
                    {language === "ENG"
                      ? "Franchise Brand Marketing Strategy"
                      : "프랜차이즈 브랜트 마케팅 전략"}
                  </a>
                </li>
                <li>
                  <a
                    href="/bp-public-institution"
                    className="hover:text-slate-400"
                  >
                    {language === "ENG"
                      ? "Public Institution and Local Government Marketing Strategy"
                      : "공공기관 및 지자체 마케팅 전략"}
                  </a>
                </li>
                <li>
                  <a
                    href="/bp-solution-advance"
                    className="hover:text-slate-400"
                  >
                    {language === "ENG"
                      ? "Solution Advancement Strategy"
                      : "Solution 고도화 전략"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </div>
  );
}
