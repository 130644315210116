import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundBusinessStrategy from "../../Assets/header background business strategy.png";
import Footer from "../../Components/Footer";
import BPSolutionAdvanceImg from "../../Assets/BP Solution Advance.png";
import BPSolutionAdvanceKORImg from "../../Assets/BP Solution Advance KOR.png";
import OtherBusiness1 from "../../Assets/Other Business Strategy 1.png";
import OtherBusiness2 from "../../Assets/Other Business Strategy 2.png";
import OtherBusiness3 from "../../Assets/Other Business Strategy 3.png";
import LeftMenu from "../../Components/LeftMenu";
import HeaderContent from "../../Components/HeaderContent";
import OtherContent from "../../Components/OtherContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function BPSolutionAdvance() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={4} />
      <HeaderContent
        title={`${
          language === "ENG" ? "Business Promotion Strategy" : "사업추진전략"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Solution Advancement Strategy"
            : "Solution 고도화 전략"
        }`}
        background={BackgroundBusinessStrategy}
        colorBorder={"#f54545"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start">
        <LeftMenu
          currentChoose={2}
          currentBold={"Solution Advancement Strategy"}
        />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8 sm:mx-0">
            <h2 className="font-bold text-2xl sm:text-3xl mb-8">
              {language === "ENG"
                ? "All in One AI Machine Solution Target"
                : "Solution 제품 고도화 전략"}
            </h2>
            <div
              className="flex flex-row items-center space-x-2"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              <span className="text-lg sm:text-xl">
                {language === "ENG"
                  ? "We want to expand the Pass-Series sequentially, focusing on the similarity of the Solution function. By 25 years, we have completed an all-in-one AI machine solution and supplied high-end AI products."
                  : "Solution 기능의 유사성을 중심으로 순차적으로 Pass-Series를 확장하고자 하며, 25년까지 All-in-one 타입의 AI Machine Solution을 완성하여 고사양 AI제품을 공급"}
              </span>
            </div>
          </div>
          <img
            src={
              language === "ENG"
                ? BPSolutionAdvanceImg
                : BPSolutionAdvanceKORImg
            }
            alt="Solution Advancement Solution"
            onContextMenu={(e) => e.preventDefault()}
            className="w-full mt-12"
            data-aos="fade-down"
            data-aos-duration="750"
          />
          <OtherContent
            title={`${
              language === "ENG" ? "Other Business Strategy" : "기타 사업 전략"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "AI BOX Solution Development Strategy"
                : "AI BOX Solution 개발전략"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Franchise Brand Marketing Strategy"
                : "프랜차이즈 브랜트 마케팅 전략"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Public Institution and Local Government Marketing Strategy"
                : "공공기관 및 지자체 마케팅 전략"
            }`}
            image1={OtherBusiness1}
            image2={OtherBusiness2}
            image3={OtherBusiness3}
            href1={"/bp-ai-box"}
            href2={"/bp-franchise"}
            href3={"/bp-public-institution"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
