import React from "react";
import Navbars from "../../Components/Navbars";
import BackgroundSolutionApplication from "../../Assets/header background solution application.png";
import Footer from "../../Components/Footer";
import SaPrevention1 from "../../Assets/sa prevention 1.png";
import SaPrevention1KOR from "../../Assets/sa prevention 1 KOR.png";
import OtherSolutions1 from "../../Assets/App Solution 1.png";
import OtherSolutions2 from "../../Assets/App Solution 2.png";
import OtherSolutions3 from "../../Assets/App Solution 3.png";
import LeftMenu from "../../Components/LeftMenu";
import OtherContent from "../../Components/OtherContent";
import HeaderContent from "../../Components/HeaderContent";
import ButtonUp from "../../Components/ButtonUp";
import { useLanguage } from "../../Context/LanguageContext";

export default function SaPreventionDisasters() {
  const { language } = useLanguage();

  return (
    <>
      <Navbars currentBold={3} />
      <HeaderContent
        title={`${
          language === "ENG" ? "AERY Solution Applications" : "솔루션 활용분야"
        }`}
        subtitle={`${
          language === "ENG"
            ? "Prevention of Early Detection of Natural Disasters"
            : "자연재해/화재/안전 조기감지 예방"
        }`}
        background={BackgroundSolutionApplication}
        colorBorder={"#f48e35"}
      />
      <div className="bg-[#fefefe] py-20 flex flex-row items-start">
        <LeftMenu
          currentChoose={1}
          currentBold={"Prevention of Early Detection of Natural Disasters"}
        />
        <div className="flex flex-col mx-0 sm:mx-8">
          <div className="flex flex-col mx-8">
            <h2 className="font-bold text-3xl lg:text-4xl mb-8">
              {language === "ENG"
                ? "Utilization of AI in detection of Natural Disasters"
                : "자연재해 AI 신경망 활용"}
            </h2>
            <p
              className="font-normal text-xl text-justify mb-8"
              data-aos="fade-down"
              data-aos-duration="750"
            >
              {language === "ENG"
                ? "When detecting an abnormal screen (water height, tree movement, small flames, etc.), a warning signal is given to the control center. It is possible to respond quickly to the scene."
                : "이상화면(물 높이, 나무 움직임, 작은 불꽃 등) 감지 시 관제센터에 경고신호를 주어 빠른 현장 대처가 가능하다."}
            </p>
            <img
              src={language === "ENG" ? SaPrevention1 : SaPrevention1KOR}
              alt="Sa Prevention Disasters 1"
              onContextMenu={(e) => e.preventDefault()}
              className="w-full self-center"
              data-aos="flip-left"
              data-aos-duration="750"
            />
          </div>
          <OtherContent
            title={`${
              language === "ENG" ? "Other Solution Area" : "기타 솔루션 영역"
            }`}
            titlecard1={`${
              language === "ENG"
                ? "Apartment Security Management"
                : "아파트 보안/화재/안전관리"
            }`}
            titlecard2={`${
              language === "ENG"
                ? "Large Mart Shopping Mall Security"
                : "대형마트/쇼핑몰 보안/화재/안전관리"
            }`}
            titlecard3={`${
              language === "ENG"
                ? "Public Security Social Safety Net"
                : "공공 방범/치안/사회안전망"
            }`}
            image1={OtherSolutions1}
            image2={OtherSolutions2}
            image3={OtherSolutions3}
            href1={"/sa-apartment-security"}
            href2={"/sa-large-mart"}
            href3={"/sa-public-security"}
          />
        </div>
      </div>
      <ButtonUp />
      <Footer />
    </>
  );
}
