import React from "react";
import LogoAery from "../Assets/Logo Aery.png";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { useLanguage } from "../Context/LanguageContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Footer() {
  const { language } = useLanguage();

  return (
    <footer className={classNames("bg-[#1a1a1a] w-screen sm:w-full")}>
      <div className="mx-auto w-full max-w-screen-xl p-8 py-10 lg:py-16">
        <div className="mb-12 md:mb-8">
          <div className="flex flex-col justify-end">
            <a
              href={"/"}
              className="h-full w-32 self-end mb-4 hover:opacity-50"
            >
              <img src={LogoAery} alt="AERY Logo" />
            </a>
            <div className="flex mt-4 space-x-5 justify-end sm:mt-0">
              <a
                href="/"
                className="text-[#999999] hover:text-gray-900 dark:hover:text-white"
              >
                <FaFacebookSquare />
              </a>
              <a
                href="/"
                className="text-[#999999] hover:text-gray-900 dark:hover:text-white"
              >
                <FaLinkedin />
              </a>
              <a
                href="/"
                className="text-[#999999] hover:text-gray-900 dark:hover:text-white"
              >
                <FaInstagramSquare />
              </a>
              <a
                href="/"
                className="text-[#999999] hover:text-gray-900 dark:hover:text-white"
              >
                <FaTwitterSquare />
              </a>
            </div>
          </div>
        </div>
        <div className="md:flex md:justify-between">
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
            <div>
              <h2 className="mb-4 text-lg font-semibold text-white">
                {language === "ENG" ? "Useful Links" : "유용한 링크"}
              </h2>
              <ul className="text-[#999999] font-medium text-xs">
                <li className="mb-2">
                  <a href="/" className="hover:underline">
                    {language === "ENG" ? "Home" : "홈페이지"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/about-us" className="hover:underline">
                    {language === "ENG" ? "About Us" : "회사소개"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/" className="hover:underline">
                    {language === "ENG" ? "Services" : "서비스"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/" className="hover:underline">
                    {language === "ENG" ? "Terms of Service" : "서비스 약관"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/" className="hover:underline">
                    {language === "ENG" ? "Privacy Policy" : "개인 정보 정책"}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-4 text-lg font-semibold text-white">
                {language === "ENG" ? "Our Products" : "에리 제품"}
              </h2>
              <ul className="text-[#999999] font-medium text-xs">
                <li className="mb-2">
                  <a href="/ai-vision" className="hover:underline">
                    {language === "ENG"
                      ? "AI Vision Features"
                      : "AI Vision 기능"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/ai-box" className="hover:underline">
                    {language === "ENG"
                      ? "AI BOX System Diagram"
                      : "AI BOX 시스템 구성도"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/ai-face-recognition" className="hover:underline">
                    {language === "ENG"
                      ? "AI Face Recognition"
                      : "AI 안면인식 솔루션"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/ai-fire-detection" className="hover:underline">
                    {language === "ENG"
                      ? "AI Fire Detection"
                      : "AI 화재감지 솔루션"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/ai-abnormal-behavior" className="hover:underline">
                    {language === "ENG"
                      ? "AI Abnormal Behavior Detection"
                      : "AI 이상행동감지 솔루션"}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/ai-object-tracking" className="hover:underline">
                    {language === "ENG"
                      ? "AI Object Tracking"
                      : "AI 객체추적 솔루션"}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-4 text-lg font-semibold text-white">
                {language === "ENG" ? "Contact Us" : "연락처"}
              </h2>
              <ul className="text-[#999999] font-medium text-xs">
                <li className="mb-2">
                  <p className="">
                    {language === "ENG"
                      ? "Gyeonggi-do, Hwaseong-si, Dongtan Boulevard 706, Dongtan IT Valley II 1101"
                      : "경기도 화성시 동탄대로 706, 동탄IT밸리II 1101호"}
                  </p>
                </li>
                <li>
                  <span className="font-bold">
                    {language === "ENG" ? "Phone:" : "핸드폰:"}
                    <span className="font-medium text-xs"> 070-4756-4545</span>
                  </span>
                </li>
                <li>
                  <span className="font-bold">
                    {language === "ENG" ? "Email:" : "이메일:"}
                    <a
                      href="mailto:info@aery.co.kr"
                      className="font-medium text-xs hover:underline hover:text-slate-600"
                    >
                      {" "}
                      info@aery.co.kr
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-8 sm:flex sm:items-center sm:justify-center">
          <span className="text-xs text-[#999999] sm:text-center">
            © 2023{" "}
            <a href="/" className="hover:underline font-bold">
              AERY
            </a>
            {language === "ENG" ? ". All Rights Reserved." : ". 판권 소유."}
          </span>
        </div>
      </div>
    </footer>
  );
}
